.topicsList {
  margin-bottom: 40px;

  h1 {
    text-align: center;
    margin-top: 20px;
    padding-bottom: 25px;
    position: relative;
  }

  h2 {
    position: relative;
    text-align: center;
    border-bottom: 1px solid $gray3;
    padding: 25px 0;
    margin-bottom: 0;
  }

  h3 {
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 10px;
  }

  .content-links {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .link-more {
    margin: 0 15px 0 auto;
  }

  .share-button {
    padding-left: 0;
  }

  .singleTopic {
    display: flex;
    align-items: flex-start;
    flex-flow: column nowrap;
    padding: 35px 20px 25px 5px;
    border-bottom: 1px solid $gray3;
    padding-right: 20px;
    padding-left: 5px;

    &:first-of-type { border-top: 1px solid $gray3; }

    @media (min-width: $screen-sm-min) {
      flex-direction: row;
    }

    &-image {
      position: relative;
      border-right: 6px solid $brown;

      @media (max-width: $screen-xs-max) { width: 100%; }

      img { @media (max-width: $screen-xs-max) { width: 100%; } }

      li {
        transform: translateX(50%);
      }
    }

    &-content {
      padding-top: 10px;
      @media (min-width: $screen-sm-min) {
        padding-left: 30px;
        padding-top: 0;
      }
    }
  }

  .themeBox {
    position: absolute;
    top: 9px;
    right: -2.5px;
    transform: translateX(50%);
  }
}

.themePage {
  margin-top: 20px;
}

.articleFull {
  margin: 0 -15px;
  @media (min-width: $screen-sm-min) {
    margin: 0;
  }

  .articleHeader {
    height: 90px;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    &:before {
      content: '';
      height: inherit;
      width: 30px;
      display: block;
      background: $brown;
    }
  }

  &-persons {
    .articleHeader {
      &:before {
        background: $themePurple;
      }
    }
  }
  &-family {
    .articleHeader {
      &:before {
        background: $themePurple;
      }
    }
  }
  &-hands {
    .articleHeader {
      &:before {
        background: $themeGreen;
      }
    }
  }
  &-bird {
    .articleHeader {
      &:before {
        background: $themeYellow;
      }
    }
  }
  &-cross {
    .articleHeader {
      &:before {
        background: $themeBlue;
      }
    }
  }
  &-heart {
    .articleHeader {
      &:before {
        background: $themeRed;
      }
    }
  }

  h1 {
    margin-bottom: 0;
    padding-left: 35px;
  }

  .themeDecoration {
    position: absolute;
    left: 8px;
    border: 2px solid #fff;
    top: 50%;
    transform: translateY(-50%);
  }

  .share {
    margin-left: auto;
  }

  p {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 26px;
    &:last-of-type {
      margin-bottom: 20px
    }
  }

  p {
    padding: 0 15px;
    @media (min-width: $screen-sm-min) {
      padding: 0
    }
  }
}

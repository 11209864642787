.topicMore {
  position: relative;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  @media (min-width: $screen-sm-min) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  @media (min-width: $screen-sm-min) {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  &-more {
    display: inline-block;
    color:  $gray4;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    padding-right: 15px;
    @media (min-width: $screen-sm-min) {
      cursor: default;
    }
    &:after {
      margin-left: 15px;
      @media (min-width: $screen-sm-min) {
        display: none;
      }
    }
  }
  &-list {
    @include clearList();
    margin-top: 10px;
    display: none;
    @media (min-width: $screen-sm-min) {
      display: inherit !important;
      justify-content: inherit;
      width: 100%;
      margin-top: 0;
    }
  }
  &-item {
    text-align: center;
    padding-bottom: 7px;
    padding-top: 7px;
    @media (min-width: $screen-sm-min) {
      display: inline-block;
    }
  }
}

.splashImage {
  position: relative;
  height: 108px;
  background-size: cover;
  background-position: center;
  &[data-yt] {
    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    &::after {
      background: url(/App_Themes/Efc/img/yt-play-arrow.png);
      background-size: cover;
      width: 17px;
      height: 17px;
    }
    &::before {
      background: rgba(0,0,0,.5);
      height: 40px;
      width: 57px;
      border-radius: 13px;
    }
    @media (min-width: $screen-lg-min) {
      &::before {
        width: 106px;
        height: 74px;
      }

      &::after {
        width: 30px;
        height: 30px;
      }
    }
  }
  @media (min-width: $screen-sm-min) {
    height: 274px;
  }
  @media (min-width: $screen-md-min) {
    height: 323px;
  }
  &--big {
    height: 140px;
    @media (min-width: $screen-sm-min) {
      height: 356px;
    }
    @media (min-width: $screen-md-min) {
      height: 420px;
    }
  }
  &--affiliates {
    height: 147px;
    @media (min-width: $screen-md-min) { height: 185px; }
  }
}

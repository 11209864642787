.affiliates {
  margin-top: 39px;
  border: 1px solid $gray3;
  border-top: 6px solid $brown;

  &.noMargin { margin-top: 0; }

  &-image {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 147px;
    background-size: cover;
    margin-bottom: 5px;
    background-position: center;
    @media (min-width: $screen-md-min) { height: 185px; }
  }

  &-name {
    transition: color $basicTransitionDuration $basicTransitionTiming;
    font-family: $font-family-serif;
    @include inlineBlock;
    margin-top: 15px;
    font-size: 22px;
    color: $black;

    &:hover { color: $navyBlue; }
  }

  &-position {
    transition: color $basicTransitionDuration $basicTransitionTiming;
    font-family: $font-family-sans-serif;
    text-transform: uppercase;
    @include inlineBlock;
    margin-bottom: 15px;
    font-weight: 700;
    margin-top: 5px;
    font-size: 13px;
    color: $black;

    &:hover { color: $navyBlue; }
  }

  h2 {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    padding-top: 15px;
    margin-left: auto;
    width: 80%;
  }
  h6 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 8px;
    margin-top: 25px;
  }
  span {
    display: inline-block;
    color:  $black;
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 23px;
  }
  .sliderButton {
    top: 46px;
    bottom: initial;
  }
  &-slide {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
  }
}

.webinars-header {
  padding-bottom: 20px;
  border-bottom: 1px solid $gray3;
  margin-bottom: 15px;

  @media (min-width: $screen-sm-min) {
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  h3 {
    font-size: 24px;
    line-height: 24px;
    margin-top: 25px;
    @media (min-width: $screen-sm-min) {
      margin-right: auto;
      font-size: 30px;
      line-height: 30px;
      margin-top: 0;
    }
  }
}

.webinars-upcoming {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  padding: 0 20px;
  margin-bottom: 10px;

  @media (min-width: $screen-sm-min) {
    padding: 0;
  }

  @media (min-width: $screen-lg-min) {
    order: 1;
    max-width: calc(1/3 * 100% - 30px);
    width: 100%;
    margin-left: 30px;
  }

  &-entries {
    display: flex;
    flex-flow: row wrap;

    @media (min-width: $screen-sm-min) {
      padding: 0 17px;
    }

    &--expanded {
      .webinars-upcoming-entry:not(:first-child) {
        display: block;
      }
    }
  }

  &-entry {
    width: 100%;
    &:not(:first-child) {
      display: none;

      @media (min-width: $screen-sm-min) {
        display: block;
      }
    }

    @media (min-width: $screen-sm-min) {
      width: calc(1 / 3 * 100%);
      padding: 0 15px;

      &:nth-child(2) {
        border-left: 1px solid $gray2;
        border-right: 1px solid $gray2;
      }
    }

    @media (min-width: $screen-lg-min) {
      padding: 0;
      width: 100%;

      &:nth-child(2) {
        border-left: 0;
        border-right: 0;
      }
      &:not(:last-child) {
        border-bottom: 1px solid $gray2;
      }
      &:not(:first-child) {
        h4 {
          margin-top: 20px;
        }
      }
    }
  }

  &-more {
    font-size: 18px;
    font-weight: 500;
    border-top: 1px solid $gray3;
    padding: 20px 0;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &--toggled {
      .icons-doubleArrow {
        transform: rotate(180deg);
      }
    }

    @media (min-width: $screen-sm-min) {
      display: none;
    }

    .icons {
      margin-left: 15px;
    }
  }

  h3 {
    display: table;
    font-family: Raleway;
    font-weight: 500;
    padding-bottom: 6px;
    line-height: 18px;
    border-bottom: 3px solid $gray2;
    padding-top: 40px;
    margin: 0 auto 35px;
  }

  h4 {
    font-size: 20px;
    line-height: 26px;
    position: relative;
    padding-left: 12px;

    &::before {
      position: absolute;
      left: 0;
      display: inline-block;
      content: '';
      height: 100%;
      width: 5px;
      border-radius: 25%;
    }

    &.purple {
      &::before {
        background-color: $themePurple;
      }
    }
  }

  p {
    line-height: 21px;
    margin: 15px 0;
  }

  time {
    background-color: $whitesmoke;
    margin: 0 -20px 15px;
    padding: 0 0 0 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 40px;
    font-size: 12px;
    font-family: Raleway;
    font-weight: 600;

    b {
      padding-right: 20px;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-lg-min) {
      margin-left: 0;
      margin-right: 0;
      height: 65px;
      padding: 10px 20px;
      b:first-child {
        display: block;
      }
    }

    span {
      color: #919191;
    }
  }

  .button {
    margin: 0;
    margin-right: auto;
  }

  .webinars-links {
    margin-bottom: 30px;
  }
}
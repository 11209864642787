.videoBoxSmall{
  border-bottom: 1px solid $gray3;
  padding: 10px 0px;
  overflow: hidden;

  &:last-child {
    margin-bottom: 30px;
  }

  @media (min-width: $screen-sm-min) {
    width: 50%;
    float: left;
    display: inline-block;
  }

  @media (min-width: $screen-lg-min) {
    width: 100%;
    float: none;
  }


  &-half {
    display: inline-block;
    width: 50%;
    float: left;
  }

  &-image {
    display: inline-block;
    position: relative;
    border-right: 5px solid $black;
  }

  &-description {

    a {
      color:  #000000;
      font-size: 15px;
      font-weight: 400;
    }

    a:hover {
      color: $blue;
      text-decoration: none;
    }
  }

  &-date {
    color:  #919191;
    font-size: 13px;
    font-weight: 500;
  }

  .themeDecoration {
    left: auto;
    right: -13px;
    position: absolute;
    margin-top: 20px;
  }
}

.youtubeFrame {
  background-color: $gray1;
  padding-top: 20px;
  margin-bottom: 20px;
  @media (min-width: $screen-md-min) {
    margin-bottom: 0;
  }
  .embed-responsive {
    margin-bottom: 18px;
    @media (min-width: $screen-sm-min) {
      margin-bottom: 24px;
    }
  }
  &-description {
    padding-bottom: 1px;
    @media (min-width: $screen-sm-min) {
      padding-left: 20px;
    }
  }
  h4 {
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 10px;
    @media (min-width: $screen-sm-min) {
      font-size: 26px;
      font-weight: 400;
      line-height: 40px;
    }
  }
  .topicDecorator {
    margin-right: 15px;
    margin-bottom: 18px;
  }
  .share {
    margin-bottom: 18px;
  }
}

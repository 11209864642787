.listOfTopics {
  margin-bottom: 30px;
  position: relative;

  ul {
    padding-left: 0px;
    padding-bottom: 20px;
  }

  ul > li {
    color: $brown;
    border-top: 1px solid $gray2;
    padding: 15px 0px 15px 15px;
    list-style: none;
    @media (min-width: $screen-sm-min) {
      list-style: disc;
      list-style-position:inside;
    }
  }
  ul > li > a {
    color: $black;
    font-weight: 400;
  }

  ul > li > a:hover {
    color: $blue;
  }
}

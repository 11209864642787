.doubleShadow {
  z-index: 0;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  @include radial-shadow(100%, top);
  @include radial-shadow(100%, bottom);
  &>.container {
    background-color: #fff;
  }
}

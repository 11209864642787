.sectionNavigator {
  position: relative;
  border: 1px solid $gray3;
  margin-bottom: 30px;
  @media (min-width: $screen-sm-min) {
    display: flex;
    align-items: center;
  }
  @media (min-width: $screen-md-min) {
    display: inline-block;
    max-width: 200px;
    float:right;
    width: 100%;
  }
  &-header {
    font-family: $font-family-sans-serif;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    padding: 15px;
    cursor: pointer;
    @media (min-width: $screen-sm-min) {
      display: block;
      padding: 0;
      padding-left: 15px;
      padding-right: 15px;
      cursor: default;
    }
    @media (min-width: $screen-md-min) {
      padding: 15px;
    }
    .icons-chevron {
      @media (min-width: $screen-md-min) {
        display: none;
      }
      &:after {
        margin-left: 10px;
      }
    }
  }
  &-right {
    @media (min-width: $screen-sm-min) {
      flex: 1;
    }
  }
  &-active {
    color:  $navyBlue;
    font-size: 13px;
    font-weight: 600;
    background-color: $gray1;
    padding: 15px;
  }
  &-list {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
    display: none;
    .active {
      a {
        font-weight: 700;
        text-decoration: underline;
      }
    }
    @media (min-width: $screen-md-min) {
      display: block !important;
    }
    li {
      border-top: 1px solid $gray1;
      a {
        display: inline-block;
        width: 100%;
        font-size: 13px;
        font-weight: 600;
        color: $gray4;
        padding: 12px 0px;
        &:hover {
          color: $navyBlue;
        }
      }
    }
  }
}

.infoStripe {
  background-color: $gray1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 30px;
  .themeIndicator {
    margin-right: auto;
    display: inline-flex;
    align-items: center;
  }
  .infoSection {
    &-right {
      display: flex;
      align-items: center;
    }
  }
  &-date {
    font-family: $font-family-serif;
    font-size: 20px;
    font-weight: 400;
    margin-right: 30px;

    &--withMargin { margin-bottom: 2px; }
  }
  .icons-print {
    margin-right: 5px;
  }
}

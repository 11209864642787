.topicIntro {
  img {
    display: block;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    @media (min-width: $screen-sm-min) {
      float: right;
      margin-left: 25px;
    }
  }
  p {
    font-size: 16px;
  }
  &.wysiwyg {
    margin-bottom: 20px;
  }
}

.bookASpeakerForm {
  background-color: $gray1;
  padding: 30px 0px;
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: $screen-md-min) {
    margin-left: 0px;
    margin-right: 0px;
  }

  h2, h3 {
    border-bottom: 1px solid $gray3;
  }
  h2 {
    padding-bottom: 10px;
  }
  h3 {
    padding-bottom: 5px;
  }
  h4 {
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  select, input[type="text"] {
    font-size: 13px;
  }
  label {
    color:  $black;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  &-expand {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }

  &-required:after {
    content: "*";
    color:  $red;
    font-size: 16px;

  }

  input, select {
    width: 100%;
    border: 2px solid $gray3;
    border-radius: 20px;
    appearance: none;
    height: 40px;
    padding-left: 20px;
    margin-bottom: 10px;
    outline: none;
  }

  input[type="date"] {
    background-image: url(../img/calendar.png);
    background-repeat: no-repeat;
    background-position: 95% 50%;
  }

  input[type="date"]::-webkit-calendar-picker-indicator, input[type="date"]::-webkit-inner-spin-button{
    appearance: none;
  }

  input[type=date]::-webkit-calendar-picker-indicator {
    background-color: transparent;
    color: transparent;
    width: 20px;
    cursor: pointer;
  }


  input[type="radio"] {
    width: 18px;
    height: 18px;
    background-image: url(../img/radioButton.png);
    padding-left: 0px;
    outline: 0;
    border: none;
    margin-bottom: 0px;
  }

  input[type="radio"]:checked {
    background-image: url(../img/radioButtonSelected.png);
  }

  input[type="submit"] {
    background-color:  $navyBlue;
    width: 270px;
    color:  $white;
    font-size: 13px;
    font-weight: 700;
    border-radius: 4px;
    appearance: none;
    border: none;
  }

  input[type="submit"]:hover {
    background-color: $darkBlue;
  }

  textarea {
    width: 100%;
    resize: none;
    border: 2px solid $gray3;
    border-radius: 20px;
    height: 80px;
  }

  &-halfWidth {
    position: relative;
    @media (min-width: $screen-sm-min) {
      width: 50%;
      float: left;
      height: 72px;

      input, select {
        width: 100%;
      }
      &--left {
        padding-right: 10px;
      }
    }

    &--tooltip {
      height: 50px;
      @media (min-width: $screen-sm-min) {
        height: 72px;
      }
    }
    &--radio {
      float: left;
      clear: none;
      display: table;
      width: 100%;
      margin-bottom: 10px;
      @media (min-width: $screen-sm-min) {
        width: 50%;
        margin-bottom: 0px;
      }

      label {
        float: left;
        clear: none;
        display: block;
        padding: 3px 1em 0 5px;
      }

      input[type=radio] {
        float: left;
        clear: none;
        margin: 2px 0 0 2px;
      }
    }

    &--table {
      display: table-cell;
      vertical-align: middle;
      height: 40px;
      padding-top: 15px;
    }
  }

  &-radio {
    float: left;
    clear: none;
    display: table;
    width: 100%;
    margin-bottom: 10px;

    label {
      float: left;
      clear: none;
      display: block;
      padding: 3px 1em 0 5px;
    }

    input[type=radio] {
      float: left;
      clear: none;
      margin: 2px 0 0 2px;
    }
  }

  &-section {
    overflow: hidden;
    margin-bottom: 10px;
  }

  &-displayTooltip {
    width: 21px;
    height: 21px;
    border-radius: 10px;
    background-color: $navyBlue;
    display: block;
    color: $white;
    font-weight: bold;
    font-size: 16px;
    padding-left: 7px;
    position: absolute;
    bottom: 20px;
  }

  &-tooltipIcon {
    position: relative;
    display: inline-block;

    &:hover{
      .bookASpeakerForm-tooltipText {
        visibility: visible;
      }
    }
  }

  &-tooltipText {
    visibility: hidden;
    width: 150px;
    background-color: $navyBlue;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0px;
    margin-left: 10px;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 110%;
    font-size: 13px;
    font-weight: 400;
    z-index: 999;

    &:after {
      content: "";
      position: absolute;
      top: 15px;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent $navyBlue transparent transparent;
    }
  }

  &-smallInput {
    width: 100%;
    label {
      width: 100%;
      display: block;
    }
    select {
      width: 168px;
    }
  }
}

.bookASpeakerForm-tooltipIcon:hover .bookASpeakerForm-tooltipText {
    visibility: visible!important;
}

.topicNavigator {
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  color: $gray4;
  margin-bottom: 30px;
  background-color: $gray1;
  @media (min-width: $screen-sm-min) {
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
  }
  a {
    display: block;
    height: 100%;
    color: $gray4;
    padding-top: 14px;
    padding-bottom: 14px;
    &:hover {
      color: $black;
    }
  }
  &-wrap {
    background-color: $gray1;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
    @media (min-width: $screen-sm-min) {
      display: none;
    }
  }
  &-list {
    @include clearList();
    @media (min-width: $screen-sm-min) {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 30px);
      margin-left: auto;
      margin-right: auto;
    }
  }
  &-selected {
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
    background-color: $navyBlue;
    padding-top: 14px;
    padding-bottom: 14px;
    color: #fff;
    position: relative;
    &:before {
      position: absolute;
      bottom: -8px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 7.5px 0 7.5px;
      border-color: $navyBlue transparent transparent transparent;
    }
    span {
      display: inline-block;
      padding-bottom: 2px;
      border-bottom: 2px solid $brown;
    }
  }
  &-element {
    background-color: $gray1;
    border-bottom: 1px solid white;
    @media (min-width: $screen-sm-min) {
      width: 100%;
      border-bottom: 0;
      border-right: 1px solid white;
      &:first-of-type {
        border-left: 1px solid white;
      }
      span {
        display: inline-block;
        padding-bottom: 2px;
        border-bottom: 2px solid transparent;
      }
      &.active {
        background-color: $navyBlue;
        position: relative;
        &:after {
          position: absolute;
          bottom: -8px;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8px 7.5px 0 7.5px;
          border-color: $navyBlue transparent transparent transparent;
        }
        a {
          color: $white;
          &:hover {
            color: $white;
            cursor: default;
          }
        }
        span {
          display: inline-block;
          padding-bottom: 2px;
          border-bottom: 2px solid $brown;
        }
      }
      &:hover {
        span {
          display: inline-block;
          padding-bottom: 2px;
          border-bottom: 2px solid $brown;
        }
      }
    }
  }
}

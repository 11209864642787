body {
  font-family: $font-family-sans-serif;
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-serif;
  font-weight: 400;
  color: $black;
  margin: 0;
  a {
    color: inherit;
    font-weight: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
}

h1 {
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 12px;

  @media (min-width: $screen-sm-min) {
    font-size: 42px;
    line-height: 54px;
    margin-bottom: 24px;
  }
}

h2 {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 12px;

  @media (min-width: $screen-sm-min) {
    font-size: 30px;
    line-height: 32px;
    margin-bottom: 18px;
  }
}

h3 {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 8px;
  @media (min-width: $screen-md-min) {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 12px;
  }
}

h4 {
  font-size: 16px;
  @media (min-width: $screen-md-min) {
    font-size: 20px;
  }
}

p {
  font-family: $font-family-sans-serif;
  font-size: 14px;
  color: $black;
  margin: 0;
  margin-bottom: 23px;
  line-height: 1.5em;
}

blockquote {
  margin-left: 16px;
  padding-left: 28px;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  border-left: 3px solid $black;
  @media (min-width: $screen-md-min) {
    margin-left: 20px;
    padding-left: 34px;
    font-size: 20px;
    line-height: 32px;
  }
}

a {
  transition: color $basicTransitionDuration $basicTransitionTiming;
  text-decoration: none;
  color: $blue;
  font-weight: 500;
  &:hover {
    color: $black;
    text-decoration: none;
  }
  &:visited, &:active, &:focus {
    text-decoration: none;
  }
  &:focus {
    outline: none;
    color: $blue;
  }
}
time {
  font-family: $font-family-serif;
  color:  #333333;
  font-size: 16px;
  font-weight: 400;
}

select {
  background-image: url('../img/selectArrow.png');
  font-family: $font-family-sans-serif;
  background-position: calc(100% - 15px);
  background-repeat: no-repeat;
  background-color: $white;
  appearance: none;
  font-size: 13px;
  cursor: pointer;
  color: $black;
  height: 40px !important;
  &:disabled {
    background-color: rgb(235, 235, 228);
    &:hover {
      cursor: not-allowed;
    }
  }
}

input[type="text"] {
  &:disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
}

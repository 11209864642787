.sideMenu {
  position: relative;

  &-header {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      transform: translateX(-50%);
      margin-right: auto;
      position: absolute;
      margin-left: auto;
      width: 320px;
      left: 50%;
      top: 0;
    }
    a {
      color: $black;
      display: block;
      width: 100%;
      height: 100%;
      &:hover {
        color: $navyBlue;
      }
    }
  }

  &-content {
    padding-bottom: 30px;

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { padding-top: 80px; }

    > ul { padding-bottom: 30px; }

    ul {
      background-image: url(../img/sideMenuBackground.png);
      list-style-type: none;
      position: relative;
      margin-bottom: 0;
      padding-left: 0;
      margin-top: 0;

      @media (min-width: $screen-md-min) {
        background-image: none;
        padding-bottom: 0;
      }

      &:before,
      &:after {
        @media (min-width: $screen-md-min) { content: none; }
      }

      &:before {
        transform: rotate(0);
        top: 0;
      }

      &:after {
        transform: rotate(180deg);
        bottom: 0;
      }

      &.target {
        display: none;

        @media (min-width: $screen-md-min) { display: block; }
      }

      .level0 {
        border-right: 1px solid $gray2;
        background-color: $white;
        text-align: center;
        position: relative;

        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
          margin-right: auto;
          margin-left: auto;
          width: 320px;
        }

        &.dropdown {
          .nextLevelTrigger {
            position: relative;

            &:before {
              transition: all $basicTransitionDuration $basicTransitionTiming;
              font-family: 'fontello';
              position: absolute;
              font-weight: 700;
              content: '\e807';
              font-size: 8px;
              color: $brown;
              right: 15px;
              top: 20px;
            }

            &.currentLevel {
              border-left-color: $gray2;
              font-weight: 700;
            }
          }
        }

        .nextLevelTrigger {
          transition: all $basicTransitionDuration $basicTransitionTiming;
          font-family: $font-family-sans-serif;
          border-bottom: 1px solid $gray2;
          border-left: 5px solid $gray2;
          padding: 15px 25px 15px 25px;
          font-weight: 400;
          font-size: 14px;
          display: block;
          color: $black;

          &:hover {
            background-color: $gray2;
            border-left-color: $gray3;

            &:before { color: $black; }
          }

          &.activeLevel {
            border-left-color: $brown;
            font-weight: 700;

            &:before { transform: rotate(-180deg); }

            +.level1 { display: block; }
          }
        }

        .level1 {
          list-style-type: none;
          padding-left: 0;
          display: none;

          a {
            transition: all $basicTransitionDuration $basicTransitionTiming;
            font-family: $font-family-sans-serif;
            border-bottom: 1px solid $gray3;
            border-left: 3px solid $gray2;
            padding: 12px 15px 12px 15px;
            background-color: $gray1;
            margin-left: 10px;
            font-weight: 500;
            font-size: 13px;
            display: block;
            color: #595959;

            &:hover {
              border-left-color: $gray3;
              background-color: $gray2;
            }

            &.activeLevel {
              border-left-color: $brown;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  &-toggleButton {
    transition: all $basicTransitionDuration $basicTransitionTiming;
    transform: translateX(-50%);
    background-color: $gray2;
    border-radius: 18px;
    position: absolute;
    cursor: pointer;
    bottom: 12px;
    height: 36px;
    width: 36px;
    z-index: 1;
    left: 50%;

    @media (min-width: $screen-md-min) { display: none; }

    &:after {
      font-family: 'fontello';
      position: absolute;
      content: '\e805';
      font-size: 17px;
      left: 10px;
      top: 7px;
    }

    &.clicked {
      transform: rotate(180deg);
      left: calc(50% - 18px);
    }
  }
}

.radialShadow {
  &--top {
    &:before {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      position: absolute;
      height: 20px;
      margin: auto;
      content: '';
      z-index: -1;
      width: 80%;
      right: 0;
      left: 0;
      top: 0;
    }
  }

  &--bottom {
    &:after {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      position: absolute;
      height: 20px;
      margin: auto;
      content: '';
      z-index: -1;
      width: 80%;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }

  &-image {
    &--top {
      &:before {
        content: '';
        background-image: url("../img/radialShadow.png");
        background-size: 100% 15px;
        transform: rotate(180deg);
        width: calc(100% - 30px);
        position: absolute;
        height: 15px;
        opacity: 0.5;
        left: 15px;
        top: -15px;
      }
    }

    &--bottom {
      &:after {
        content: '';
        background-image: url("../img/radialShadow.png");
        background-size: 100% 15px;
        width: calc(100% - 30px);
        position: absolute;
        bottom: -15px;
        opacity: 0.5;
        height: 15px;
        left: 15px;
      }
    }

    &--left {
      &:after {
        content: '';
        background-image: url("../img/radialShadowVertical.png");
        background-size: 15px 100%;
        width: 15px;
        position: absolute;
        left: 0px;
        opacity: 0.5;
        height: 100%;
        top: 0;
        bottom: 0;
      }
    }
  }
}

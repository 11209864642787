.aboutBlog {
  border: 1px solid $gray3;
  border-top: none;
  width: 100%;
  margin-bottom: 40px;
  @media (min-width: $screen-sm-min) {
    width: 200px;
    float: right;
  }
  .button-chevron {
    font-family: $font-family-sans-serif;
    color:  $black;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height:60px;
    border-top: 3px solid $navyBlue;
    padding-left: 15px;
    padding-right: 15px;
    &:after {
      @media (min-width: $screen-sm-min) {
        display: none;
      }
    }
  }

  &-feedback {
    padding: 5px 15px 25px 15px ;
    > a {
      font-family: $font-family-sans-serif;
      font-size: 14px;
      color: $black;

      &:hover {
        color: $navyBlue;

        &:before { background-color: $brownOpacity; }
      }

      &:before {
        transition: background-color $basicTransitionDuration $basicTransitionTiming;
        background-color: $brown;
        font-family: 'fontello';
        display: inline-block;
        vertical-align: 2px;
        border-radius: 10px;
        margin-right: 8px;
        font-size: 12px;
        color: $white;
        height: 20px;
        width: 20px;
      }

      &:first-of-type {
        &:before {
          padding-left: 5px;
          padding-top: 3px;
          content: '\e821';
          font-size: 10px;
        }
      }

      &:last-of-type {
        &:before {
          padding-left: 4px;
          padding-top: 1px;
          content: '\e80e';
        }
      }
    }
  }

  .button-plus {
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    font-family: $font-family-sans-serif;
    color:  $black;
    font-size: 13px;
    font-weight: 700;
    line-height: 32px;
    text-transform: uppercase;
    height: 40px;
    border-top: 1px solid $gray3;
    cursor: pointer;
    @media (min-width: $screen-sm-min) {
      height: 50px;
    }
    &:before {
      margin-right: 10px;
    }
  }
  &-wrap {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    display: none;
  }
  &-tags {
    padding-bottom: 20px;
    max-height: 250px;
    overflow-y: scroll;
    span {
      display: block;
      a {
        text-transform:capitalize;
        display: inline-block;
        font-size: 11px !important;
        font-weight: 700;
        padding-left: 7px;
        padding-right: 7px;
        padding-bottom: 5px;
        padding-top: 5px;
      }
    }
  }
  &-posts{
    @include clearList();
    li {
      border-bottom: 1px solid $gray3;
      &:last-of-type {
        border-bottom: 0;
      }
      &:first-of-type {
        h3 {
          margin-top: 0;
        }
      }
    }
    h3 {
      &.hands {
        &:before { background-color: $themeGreen; }
      }

      &.cross {
        &:before { background-color: $themeBlue; }
      }

      &.persons {
        &:before { background-color: $themePurple; }
      }

      &.bird {
        &:before { background-color: $themeYellow; }
      }

      &.heart {
        &:before { background-color: $themeRed; }
      }

      &.family {
        &:before { background-color: $themePurple; }
      }
      font-size: 16px;
      position: relative;
      padding-left: 15px;
      line-height: 19px;
      margin-top: 15px;
      margin-bottom: 15px;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 5px;
        height: 100%;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: red;
        border-radius: 10px;
      }
    }
  }
  &-description {
    display: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    @media (min-width: $screen-sm-min) {
      display: block;
    }
  }
}

.share {
  $that: '.share';
  display: inline-block;
  cursor: pointer;

  &-onRight {
    float: right;

    #{$that}-media { right: 0; }
  }

  &-onLeft {
    #{$that}-media { left: 0; }
  }

  &-right {
    // float: right;

    // #{$that}-media {
    //   right: 0;
    // }
  }
  &-button {
    position: relative;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    color: $navyBlue;
    background-color: #fff;
    padding-top: 5.5px;
    padding-bottom: 5.5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 100px;
    // &:hover {
    //   #{$that}-media {
    //     display: block;
    //   }
    // }
    &:before {
      font-family: 'fontello';
      content: '\e803';
      font-size: 14px;
      padding-right: 10px;
    }
  }
  &-media {
    display: none;
    position: absolute;
    // left: 0;
    top: -4px;
    white-space: nowrap;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    border-radius: 100px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
    &:before {
      font-family: 'fontello';
      content: '\e803';
      font-size: 14px;
      padding-right: 18px;
      vertical-align: middle;
    }
    li {
      display: inline-block;
    }
  }
  &-icon {
    display: inline-block;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    margin-left: 3.5px;
    margin-right: 3.5px;
    vertical-align: middle;
    text-align: center;
    &:hover {
      opacity: 0.7;
    }
    &:before {
      font-family: 'fontello';
      color: $white;
      font-size: 16px;
      font-weight: 400;
      line-height: 29px;
      cursor: pointer;
    }
    &--mail {
      background-color: $black;
      &:before {
        line-height: 26px;
        content: '\e80e';
      }
    }
    &--facebook {
      background-color: $navyBlue;
      &:before {
        line-height: 30px;
        content: '\f09a';
      }
    }
    &--twitter {
      background-color: #00a6d3;
      &:before {
        line-height: 29px;
        content: '\f099';
      }
    }
    &--googleplus {
      background-color: #dc4e41;
      &:before {
        content: '\f0d5';
      }
    }
    &--pinterest {
      background-color: #CB2027;
      &:before {
        line-height: 30px;
        content: '\f231';
      }
    }
  }
}

.universalPagination {
  $that: '.universalPagination';

  text-align: center;
  position: relative;
  margin-top: 10px;
  margin-bottom: 20px;
  height: 81px;

  &-NextPage,
  &-PreviousPage {
    transition: background-color $basicTransitionDuration $basicTransitionTiming;
    font-family: $font-family-sans-serif;
    text-transform: uppercase;
    background-color: $gray2;
    padding-bottom: 11px;
    @include inlineBlock;
    border-radius: 3px;
    text-align: center;
    position: absolute;
    padding-top: 12px;
    font-weight: 700;
    font-size: 13px;
    color: $black;
    width: 41px;
    top: 20px;

    @media (min-width: $screen-sm-min) {
      display: none;
      width: 200px;
    }

    &:before {
      font-family: 'fontello';

      @media (min-width: $screen-sm-min) { display: none; }
    }

    &--text {
      display: none;

      @media (min-width: $screen-sm-min) { display: inline-block; }
    }

    &:hover { background-color: $gray3; }
  }

  &-PreviousPage {
    left: 0;

    &:before { content: '\e808'; }
  }

  &-NextPage {
    right: 0;

    &:before { content: '\e809'; }
}

  &-Pages {
    @include inlineBlock;
    padding-top: 25px;

    &--CurrentPage,
    &--Page {
      font-family: $font-family-sans-serif;
      @include inlineBlock;
      padding-right: 10px;
      padding-left: 10px;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      color: $black;

      span {
        border-bottom: 2px solid $gray3;
        padding-bottom: 3px;
      }
    }

    &--CurrentPage {
      span {
        border-bottom-color: $brown;
        font-weight: 600;
      }
    }

    &--Page {
      &:hover {
        span { border-bottom-color: $gray4; }
      }
    }
  }

  &.withoutPageNumbers {
    #{$that} {
      &-Pages { display: none; }
    }
  }
}

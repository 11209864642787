.faqSearch {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  color: $black;
  padding-bottom: 25px;
  &-phrase {
    background: $gray2;
    padding: 7px 10px;
    font-weight: 600;
    line-height: 100%;
    margin-left: 10px;
  }
  h2 {
    display: block;
    width: 100%;
    margin-bottom: 12px;
  }
  &-search {
    padding-left: 0;
    background: none;
  }
}

.faqBack {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  a {
    margin: 30px 0;
  }
}


.pageNotFound {
  text-align: center;
  margin: 30px 0;

  &-image { width: 100%; }

  &-title {
    font-family: $font-family-serif;
    padding-bottom: 20px;
    margin-bottom: 35px;
    position: relative;
    padding-top: 15px;
    font-weight: 400;
    font-size: 36px;
    color: $black;
  }

  &-error,
  &-info {
    font-family: $font-family-sans-serif;
    font-size: 16px;
    color: $black;
  }

  &-error {
    margin-bottom: 30px;
    font-weight: 400;
  }

  &-info {
    @include inlineBlock;
    font-weight: 600;
    max-width: 550px;
  }
}

.speakerBiography {
  h2 {
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 26px;
    line-height: 100%;
    padding: 30px 0 5px;
    margin: 0;
  }
  &-bottom {
    background: $gray1;
    border-top: 4px solid $brown;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
    &::before, &::after {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%) translateY(-50%);
    }
    &::before {
      content: "";
      display: block;
      background: $brown;
      width: 42px;
      height: 42px;
      border-radius: 50%;
    }
    &::after {
      font-family: "fontello";
      content: "\e820";
      color: #fff;
      font-size: 24px;
    }
    a {
      margin-top: 30px;
    }
  }

  .link-more {
    margin-bottom: 0;
  }
}
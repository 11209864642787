.topicBox {
  $paddingSide: 20px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  padding: 30px $paddingSide;
  padding-bottom: 5px;
  margin-bottom: 5px;
  width: 100%;
  @media (min-width: $screen-sm-min) {
    width: 48%;
    float: left;
  }
  &-header {
    font-family: $font-family-sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 18px;
    span {
      display: inline-block;
      padding-bottom: 2px;
      border-bottom: 3px solid $themeYellow;
    }
  }
  &-time {
    font-size: 16px;
    font-weight: 400;
  }
  &-title {
    font-size: 24px;/* Przybliżenie (wynika z podstawienia czcionki) */
    font-weight: 400;
    margin-bottom: 8px;

    &.decorated {
      margin-bottom: 15px;
      padding-left: 15px;
      position: relative;
      margin-top: 15px;

      &:before {
        background-color: $gray2;
        height: calc(100% - 10px);
        position: absolute;
        display: block;
        content: '';
        width: 5px;
        top: 5px;
        left: 0;
        border-radius: 2px;
      }

      &--hands {
        &:before { background-color: $themeGreen; }
      }

      &--cross {
        &:before { background-color: $themeBlue; }
      }

      &--persons {
        &:before { background-color: $themePurple; }
      }

      &--bird {
        &:before { background-color: $themeYellow; }
      }

      &--heart {
        &:before { background-color: $themeRed; }
      }

      &--family {
        &:before { background-color: $themePurple; }
      }
    }
  }
  &-divider {
    @extend .divider;
    margin-bottom: 23px;
  }
  &-image {
    height: 160px;
    margin-bottom: 12px;
    background-size: cover;
    margin-left: -$paddingSide;
    margin-right: -$paddingSide;
    @media (min-width: $screen-sm-min) {
      height: 200px;
    }
  }
  &-paragraph {
    &-faq  {
      span {
        background: $beige;
      }

      &-fullDescription { display: none; }
    }
  }
  &-list {
    h4 {
      font-family: $font-family-sans-serif;
      color:  $gray4;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 17px;
    }
    ul {
      @include clearList();
      padding-bottom: 11px;
    }
    li {
      font-size: 14px;
      font-weight: 500;
      margin-top: 10px;
      margin-bottom: 10px;
      a {
        color: $black;
      }
    }
  }
  &-bottom {
    background: $whitesmoke;
    margin: 0 -15px;
    padding: inherit;
    padding-bottom: 20px;
    position: relative;
    @media (min-width: $screen-sm-min) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      color: $black;
    }

    &-themeDecorations {
      display: flex;
      align-items: flex-start;
      &-container {
        display: flex;
        margin-right: 9px;
      }
    }
    .themeDecoration {
      height: 20px;
      width: 20px;
      font-size: 19px;
      margin-right: 1px;

      &:before {
        transform: translateX(-50%);
        position: relative;
        left: 50%;
      }
    }

    .helpers-textCenter {
      text-align: left;
    }

    .button-basic {
      padding: 9px 20px;
      margin: 20px 0 0;
      @media (min-width: $screen-sm-min) {
        margin-top: 0;
      }
    }
  }
  &--faq {
    padding: 20px 15px 0;
    margin-bottom: 35px;
    width: 100%;
    h5 {
      display: none;
      font-family: $font-family-sans-serif;
      color: $black;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 12px;
    }
    .topicBox-title.decorated {
      margin-top: 0;
    }
    ul {
      padding: 0;
      margin: 0;
    }
    li {
      border: none !important;

      &:not(:first-child) { display: none; }

      a { white-space: nowrap; }
    }
    p {
      margin-bottom: 20px;
    }
    &-shown {
      h5 {
        display: block;
      }
      li:not(:first-child) {
        display: flex;
        align-items: center;
      }
      li {
        border-top: 1px solid $gray3 !important;
        padding: 10px 0;
        @media (min-width: $screen-sm-min) {
          margin-right: 170px;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
      .topicBox-bottom {
        @media (min-width: $screen-sm-min) {
          display: block;
          .button-basic {
            position: absolute;
            margin-top: -30px;
            right: 15px;
          }
        }
        &-themeDecorations {
          &-container {
            justify-content: flex-end;
            width: 80px;
            flex: 0 0 80px;
          }
        }
      }

      .topicBox-paragraph-faq-shortDescription { display: none; }

      .topicBox-paragraph-faq-fullDescription { display: block; }
    }
  }
}

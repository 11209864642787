.newsletterSidebar {
  position: relative;
  background-color: $gray1;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 35px 15px;
  padding-top: 22px;
  padding-bottom: 39px;
  border-top: 4px solid $brown;
  margin-bottom: 40px;
  display: none;

  @media (min-width: $screen-md-min) { display: block; }

  &:before {
    display: inline-block;
    text-align: center;
    line-height: 34px;
    font-family: 'fontello';
    background-color: $brown;
    width: 36px;
    height: 36px;
    content: '\e80e';
    color: white;
    border-radius: 100%;
    position: absolute;
    top: -18px;
    left:0;
    right: 0;
    margin: auto;
    font-size: 21px;
  }
  &-header {
    font-size: 24px;
    text-align: center;
    margin-bottom: 12px;
  }
  &-description {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }
  input[type="text"] {
    border: 2px solid $gray3;
    border-radius: 20px;
    height: 36px;
    text-align: center;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }
  .helpers-textCenter {
    width: 100%;
  }
  .elementOffset {
    position: absolute;
    left: 0;
    bottom: -24px;
  }
  .button {
    margin: 0;
  }
  .InfoMessage {
    color: #009943;
  }
  .ErrorMessage {
    color: #e62600;
  }
}

.bigSplashImage {
  height: 108px;
  background-size: cover;
  background-position: center;
  margin: 15px 0 5px 0;
  @media (min-width: $screen-sm-min) {
    height: 274px;
  }
  @media (min-width: $screen-md-min) {
    height: 434px;
    margin-top: 30px;
  }
}

.webinars {

  .carouselBox-image {
    cursor: pointer;
    position: relative;

    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    &::after {
      background: url(/App_Themes/Efc/img/yt-play-arrow.png);
      background-size: cover;
      width: 17px;
      height: 17px;
    }

    &::before {
      background: rgba(0,0,0,.5);
      height: 40px;
      width: 57px;
      border-radius: 13px;
    }

    &:hover::before {
      background: rgba(0,0,0,.75);
    }
  }

  .headers-withDrop {
    margin-bottom: 5px;
  }

  .splashBackground {
    background: $whitesmoke;
    padding: 15px;
    position: relative;

    p {
      line-height: 26px;
      margin: 15px 0;
    }

    .share {
      margin-left: 20px
    }

    @media (min-width: $screen-lg-min) {
      margin-right: 0;
    }
  }

  .splashImage[data-yt] {
    @media (min-width: $screen-lg-min) {
      &::before {
        width: 106px;
        height: 74px;
      }

      &::after {
        width: 30px;
        height: 30px;
      }
    }
  }

  h2 {
    font-size: 22px;
    position: relative;
    line-height: 28px;
    margin-top: 15px;
    padding: 0 0 0 12px;
    margin-bottom: 0;

    &::before {
      position: absolute;
      left: 0;
      display: inline-block;
      content: '';
      height: 100%;
      width: 5px;
      border-radius: 25%;
    }

    &.purple::before {
      background-color: $themePurple;
    }

    @media (min-width: $screen-sm-min) {
      font-size: 26px;
      line-height: 36px;
    }
  }

  .publish-time {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    padding-top: 25px;

    span {
      color: #919191;
    }
    time {
      color: #000;
      padding-left: 8px;
      font-family: Raleway;
    }
  }

  &-links {
    padding: 5px 0 15px;

    .link {
      text-align: center;
      margin-bottom: 0;
      width: 70px;
    }

    > div {
      margin-top: 5px;
      float: right;

      .themeDecoration {
        // @include inlineBlock;
        // border-radius: 12px;
        // text-align: center;
        // padding-top: 4px;
        font-size: 23px;
        vertical-align: top;
        height: 24px;
        width: 24px;
        
        &:not(:last-of-type) { margin-right: 3px; }
      }
    }

    &--centered {
      > div {
        position: relative;
        margin-top: 0;
        display: inline-block;
        vertical-align: -10px;
        text-align: center;
        width: calc(100% - 110px;);

        > div {
          padding-left: 15px;

          > a {
            margin-bottom: 4px;
            margin-left: 1px;
            margin-right: 1px !important;
          }
        }
      }
    }
  }

  .webinar-carousel {
    display: flex;
    flex-flow: wrap row;
    margin: 0 -15px;
    @media (min-width: $screen-lg-min) {
      order: 0;
      max-width: calc(2/3 * 100%);
      width: 100%;
      margin-top: -20px;
    }
  }

  &-content {
    .youtubeFrame  {
      margin-bottom: 12px;
    }

    @media (min-width: $screen-lg-min) {
      display: flex;
      align-items: flex-start;
      margin-right: -30px;
    }
  }

  .helpers-textCenter {
    width: 100%;
    margin: 0 auto;
  }

  .button--expand {
    max-width: 270px;

    @media (min-width: $screen-sm-min) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    @media (min-width: $screen-lg-min) {
      margin: 30px auto;
    }
  }
}

.switchBox {
  margin-bottom: 30px;
  &-button {
    display: inline-block;
    background-color: $gray1;
    padding-top: 13px;
    padding-bottom: 20px;
    margin-left: -0.25em;
    width: 50%;
    text-align: center;
    span {
      color: $gray4;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 700;
    }
    &:first-of-type {
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
    }
    &:last-of-type {
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
    }
    &:hover {
      span {
        border-bottom: 2px solid $brown;
        padding-bottom: 3px;
      }
    }
    &--active {
      background-color: $navyBlue;
      span {
        color: $white;
        border-bottom: 2px solid $brown;
        padding-bottom: 3px;
      }
    }
  }

  &--constWidth {
    max-width: 360px;
    margin: auto;
  }

}

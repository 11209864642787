.videoBox {
  background-color: $gray1;
  padding: 20px 15px 30px 15px;
  margin-right: -15px;
  margin-bottom: 30px;

  @media (min-width: $screen-lg-min) {
    margin-bottom: 0px;
  }

  @media (min-width: $screen-md-min) {
    margin-right: 0px;
    padding-left: 15px;
  }

  @media (min-width: $screen-md-min) {
    padding-left: 30px;
  }

  h1 {
    font-size: 26px;
    margin-bottom: 15px;
    @media (min-width: $screen-sm-min) {
      font-size: 36px;
    }
  }

  &-videoWrapper{
    position: relative;
  	padding-bottom: 56.25%; /* 16:9 */
  	padding-top: 25px;
  	height: 0;
    margin-bottom: 30px;

    &--noMarginBottom {
      margin-bottom: 0px;
    }

    iframe {
      position: absolute;
    	top: 0;
    	left: 0;
    	width: 100%;
    	height: 100%;
    }
  }

  h2 {
    font-size: 22px;
    @media (min-width: $screen-sm-min) {
      font-size: 26px;
    }
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
  }

  &-topic {
    color:  $gray4;
    font-size: 11px;
    font-weight: 700;
    padding-right: 10px;
    text-transform: uppercase;
  }

  &-topicLabel {
    font-family: $font-family-sans-serif;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 5px;
    margin-right: 15px;
    font-weight: 700;
    padding: 3px 5px;
    font-size: 11px;
    color: $white;

    &:hover {
      color: $white;
    }
  }

  .share { float: none; }
}

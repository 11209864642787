.topicContent {
  &-- {
    .topicBox-header span {
      border-color: $brown;
    }
  }
  &--resources {
    .topicBox-header span {
      border-color: $brown;
    }
  }
  &--hands {
    .topicBox-header span {
      border-color: $themeGreen;
    }
  }

  &--cross {
    .topicBox-header span {
      border-color: $themeBlue;
    }
  }

  &--persons {
    .topicBox-header span {
      border-color: $themePurple;
    }
  }

  &--bird {
    .topicBox-header span {
      border-color: $themeYellow;
    }
  }

  &--heart {
    .topicBox-header span {
      border-color: $themeRed;
    }
  }

  &--family {
    .topicBox-header span {
      border-color: $themePurple;
    }
  }

  &--blank {
    .topicBox-header span {
      border-color: $gray2;
    }
  }
}

.searchBoxHeader {
  /* Element variables */
  $elementHeight: 40px;

  border-radius: $elementHeight/2;
  // background-color: $gray1;
  height: $elementHeight;
  position: relative;
  width: 290px;

  label { display: none; }

  input[type="text"] {
    padding-right: $elementHeight*2;
    border-radius: $elementHeight/2;
    padding-left: $elementHeight/2;
    // background-color: transparent;
    background-color: $gray1;
    height: $elementHeight;
    font-size: 13px;
    color: black;
    border: none;
    width: 100%;

    &:focus { outline: none; }
  }

  input[type="submit"] {
    width: $elementHeight+$elementHeight/2;
    border-radius: $elementHeight/2;
    background-color: transparent;
    height: $elementHeight;
    position: absolute;
    border: none;
    z-index: 1;
    right: 0;
    top: 0;

    &:focus { outline: none; }

    &:hover {
      + .searchBoxHeader-searchIcon {
        &:after { color: $black; }
      }
    }
  }

  &-searchIcon {
    &:after {
      transition: color $basicTransitionDuration $basicTransitionTiming;
      transform: translateY(-50%);
      font-family: 'fontello';
      right: $elementHeight/2;
      position: absolute;
      content: '\e802';
      font-size: 18px;
      color: $gray4;
      z-index: 0;
      top: 50%;
    }
  }
}

.contactBox {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  margin-top: 20px;
  padding: 30px 15px;
  text-align: center;
  margin-bottom: 65px;
  &-title{
    font-size: 18px;
    font-weight: 500;
    border-bottom: 3px solid $gray2;
    display: inline-block;
    margin: auto;
  }

  &-contactAddress {
    text-align: left;
    padding: 20px 0px;
    border-bottom: 1px solid $gray3;
    word-wrap: break-word;
  }

  &-contactAddress:last-child {
    border-bottom: none;
  }

  &-contactAddress h3 {
    color:  $black;
    font-size: 20px;
    font-weight: 400;
    font-family: $font-family-serif;
  }

  &-contactAddress p {
    color: $black;
    font-size: 14px;
    font-weight: 400;
  }
}

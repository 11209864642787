.simpleListItem {
  margin: 20px 20px 0 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid $gray2;

  &-title {
    font-family: $font-family-sans-serif;
    border-left: 3px solid $brown;
    text-transform: uppercase;
    margin-bottom: 10px;
    padding-left: 8px;
    font-weight: 700;
    font-size: 13px;
    color: $black;
  }

  &-text {
    font-family: $font-family-serif;
    margin-bottom: 10px;
    font-size: 15px;
    color: $black;
  }

  &-otherContent {
    .downloadButton {
      text-transform: uppercase;
      font-size: 13px;

      &:before {
        transition: all $basicTransitionDuration $basicTransitionTiming;
        background-color: $brown;
        font-family: 'fontello';
        display: inline-block;
        vertical-align: 1px;
        border-radius: 10px;
        padding-left: 5px;
        margin-right: 6px;
        padding-top: 2px;
        content: '\e81f';
        font-size: 11px;
        color: $white;
        height: 20px;
        width: 20px;
      }

      &:hover {
        &:before {
          background-color: $gray3;
          color: $black;
        }
      }
    }
  }
}

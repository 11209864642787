.sitemap {
  @media (min-width: $screen-md-min) {
    border-left : 1px solid $gray3;
  }

  .icons-print {
    @media (max-width: $screen-xs-max) { margin-bottom: 15px; }
  }

  &--noBorder{
    border-left: none;
  }

  & ul {
    li {
      color: $brown;
      a {
        color: $black;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;

        &:hover { color: $navyBlue; }
      }
    }
  }

  & ul ul {
    list-style: none;
    padding: 5px 0 15px 0;

    li {
      a {
        color: $black;
        font-size: 13px;
        font-weight: 500;
        line-height: 24px;

        &:hover { color: $navyBlue; }
      }
    }
  }

  & ul ul ul {
    list-style: none;
    padding: 0 0 10px 0;
    margin-left: 10px;
    li {
      a {
        color: $darkgray;
        font-size: 12px;

        &:hover { color: $navyBlue; }
      }
    }
  }
}

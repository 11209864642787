.link {
  &-more {
    display: inline-block;
    color: $black;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    padding-bottom: 3px;
    border-bottom: 2px solid $brown;
    margin-bottom: 30px;
    &:hover {
      border-bottom: 2px solid $gray3;
    }
    &--gray {
      border-bottom: 2px solid $gray3;
      &:hover {
        border-bottom: 2px solid $black;
      }
    }
  }
  &-telephone, &-landline, &-fax {
    display: block;
    color: $black;
    margin-bottom: 5px;
    &:before {
      font-family: 'fontello';
      padding-right: 10px;
    }
    &:hover {
      color: $blue;
    }
    &--serif {
      font-family: $font-family-serif;
      font-size: 18px;
      font-weight: 400;
    }
  }
  &-telephone {
    &:before {
      content: '\e804';
    }
  }
  &-landline {
    &:before {
      content: '\e810';
    }
  }
  &-fax {
    &:before {
      content: '\e80b';
    }
  }
  &--clear {
    margin: 0;
  }
  &-mail {
    display: block;
    color: $blue;
    margin-bottom: 5px;
    &:hover {
      color: $black;
      &:before {
        color: $blue;
      }
    }
    &:before {
      font-family: 'fontello';
      padding-right: 13px;
      color: $black;
      content: '\e80e';
    }
    &--serif {
      font-family: $font-family-serif;
      font-size: 18px;
      font-weight: 400;
    }
  }
  &-map {
    font-family: $font-family-sans-serif;
    color: $blue;
    font-size: 14px;
    font-weight: 600;
    display: block;
    &:hover {
      color: $black;
    }
    &:before {
      font-family: 'fontello';
      padding-right: 10px;
      content: '\e81d';
    }
  }

}

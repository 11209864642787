.speakersBox {
  position: relative;

  &-header {
    padding: 20px 0px;
  }

  &-select {
    display: block;

    @media (min-width: $screen-sm-min) {
      float: right;
    }

    @media (min-width: $screen-sm-min) {
      select { width: 250px; }
    }
  }

  &-speakerContainer {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
    margin-bottom: 30px;
    text-align: center;
    padding-bottom: 50px;
    position: relative;
  }

  &-image {
    height: 260px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media (min-width: $screen-lg-min) { height: 190px; }
  }

  &-description {
    padding: 15px;

    h2 {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    p {
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 15px;
    }

    .link-more {
      position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    margin-bottom: 0;
    }
  }
}

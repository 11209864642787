.print-header {
  padding-bottom: 20px;
  border-bottom: 1px solid $gray2;
  margin-bottom: 20px;
}

.print-content {
  .wysiwyg {
    width: 100% !important;
  }
  img {
    display: none !important;
  }
}

.print-view,
.print-nav {
  padding-left: 40px;
  padding-right: 40px;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
}

.print-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 10px;
  .back {
    cursor: pointer;
    &:before {
      content: '\21ab';
      margin-right: 5px;
    }
  }
}

.print-footer {
  border-top: 1px solid $gray2;

  .footer-credits {
    display: inline-block;
    float: left;
    width: 50%;
    text-align: left;
  }

  .print-date {
    display: inline-block;
    float: left;
    width: 50%;
    text-align: right;
        margin-top: 26px;
  }
}

@media print {
  .print-view {
    display: block;
    padding-left: 0;
    padding-right: 0;
    max-width: auto;
  }

  .print-nav {
    display: none;
  }

  .print-content {
    padding-left: 0;
    padding-right: 0;
  }
}

/*
* Example of use
*
* <div class="themeDecoration themeDecoration-heart"></div>
*/

.themeDecoration {
  $element-size: 36px;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: 'fontello';
  background-color: #fff;
  text-align: center;
  font-weight: 400;
  border-radius: 100px;
  color: #fff;
  height: $element-size;
  width: $element-size;
  font-size: 33px;

  &:hover { color: white; }

  &--petit {
    $element-size: 20px;

    height: $element-size;
    width: $element-size;
    font-size: 12px;
  }

  &-hands {
    background-color: $themeGreen;

    &:before { content: '\e818'; }
  }

  &-cross {
    background-color: $themeBlue;

    &:before { content: '\e819'; }
  }

  &-persons {
    background-color: $themePurple;

    &:before { content: '\e81a'; }
  }

  &-bird {
    background-color: $themeYellow;

    &:before { content: '\e81b'; }
  }

  &-heart {
    background-color: $themeRed;

    &:before { content: '\e81c'; }
  }
}

.contactForm {
  padding: 30px 15px;
  background-color:$whitesmoke;
  margin-right: -15px;
  margin-left: -15px;
  width: auto;
  @media (min-width: $screen-sm-min) {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  @media (min-width: $screen-md-min) {
    padding: 30px 10%;
  }

  label {
    display: block;
    font-family: $font-family-sans-serif;
    color:  #000000;
    font-size: 13px;
    font-weight: 600;
    margin-left: 10px;
    margin-bottom: 5px;
  }

  input[type="text"], select {
    width: 100%;
    border: 2px solid $gray3;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 15px;
    padding: 0px 20px;
    outline: none;
  }

  input[type="text"].short {
    width: 202px;
  }

  textarea {
    border: 2px solid $gray3;
    resize: vertical;
    border-radius: 20px;
    margin-bottom: 15px;
    padding: 0px 10px;
    width: 100%;
    height: 205px;
  }

  input[type="submit"] {
    background-color: #5a7da1;
    color: #fff;
    display: inline-block;
    padding: 15px 24px;
    font-size: 13px;
    font-weight: 700;
    border-radius: 3px;
    text-transform: uppercase;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    border: none;
    width: 270px;
  }

  input[type="submit"]:hover {
    background-color: #dedede;
    color: #000;
  }

  .Error input[type="submit"] {
    border-color: $red;
  }

  .EditingFormErrorLabel {
    color: $red;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(../img/selectArrow.png);
    background-repeat: no-repeat;
    background-position: 95% 50%;
    @media (min-width: $screen-md-min) {
      background-position: 98% 50%;
    }
  }

  &--webinarRegistration {
    height: 100vh;
    text-align: center;
    input[type="text"] {
      max-width: 340px;
    }
    .form-group-submit {
      text-align: center;
    }
  }
}

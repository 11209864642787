.videoGalleryMainVideo {
  background-color: $gray1;

  padding: 20px;

  .videoBox-videoWrapper {
    margin-left: 30px;
  }

  &-description {
    margin-bottom: 20px;
    padding-top: 20px;
    padding-left: 30px;

    @media (min-width: $screen-md-min) {
      padding-top: 0px;
      padding-left: 0px;
    }
  }

  h2 {
    font-size: 22px;
      @media (min-width: $screen-sm-min) {
        font-size: 26px;
      }
  }

  p {
    line-height: 24px;
  }
}

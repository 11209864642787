.summaryBox {
  margin-bottom: 20px;

  &--decorated {
    position: relative;
    padding-top: 30px;
  }

  &-simple {
    margin-bottom: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

    .boxImage {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: relative;
      display: block;
      height: 140px;
      z-index: 1;
    }

    .boxContent {
      padding: 20px 20px 0 20px;
      position: relative;
      z-index: 0;

      &-title { font-size: 22px; }
    }

    &--wide {
      .boxImage { height: 220px; }

      .boxContent { text-align: center; }
    }
  }
}

.topicResourceKits {
  position: relative;

  h2 {
    padding: 20px 0px;
    margin-bottom: 0px;
    display: block;
    @media (min-width: $screen-sm-min) {
      display: inline-block;
    }
  }

  &-header {
    margin-bottom: 20px;
    border-bottom: 1px solid $gray3;

    span {
      display: block;
      margin-bottom: 10px;
      @media (min-width: $screen-sm-min) {
        display: inline-block;
      }
    }

    .decoratedSelect {
      @media (min-width: $screen-sm-min) { select { width: 250px; } }
    }
  }

  .share-button {
    margin-bottom: 20px;
    @media (min-width: $screen-sm-min) {
      margin-bottom: 0px;
    }
    @media (min-width: $screen-md-min) {
      margin-bottom: 20px;
    }
    @media (min-width: $screen-lg-min) {
      margin-bottom: 0px;
    }
  }
}

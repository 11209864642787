.contentTypeSelector {
  background-color: $gray1;
  height: 111px;

  @media (min-width: $screen-sm-min) { height: 61px; }

  &-label {
    font-family: $font-family-sans-serif;
    text-transform: uppercase;
    background-color: $white;
    padding-left: 25px;
    padding-top: 16px;
    font-weight: 700;
    font-size: 13px;
    display: block;
    color: $black;
    height: 50px;
    width: 100%;

    @media (min-width: $screen-sm-min) {
      @include inlineBlock(top);
      height: 60px;
      width: 145px;
    }
  }

  .decoratedSelect {
    display: block;
    background-color: $white;
    border-radius: 20px;
    margin-left: 15px;
    margin-top: 10px;
    padding-bottom: 0;

    @media (min-width: $screen-sm-min) { @include inlineBlock(top); }
  }
}

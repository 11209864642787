#donation-form {
  div[lum-scope="frequency"], div[lum-scope="donationLevels"] {
    display: block!important;
    label {
      width: 100%!important;
      margin-bottom: 10px;
      @media(min-width: $screen-sm-min) {
        width: 20%!important;
        margin-bottom: 0px!important;
      }
    }
    @media(min-width: $screen-sm-min) {
      display: -webkit-box!important;
      display: -ms-flexbox!important;
      display: flex!important;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin: 2px -7.5px 10px;
    }
  }

  div[class="billing.name.title"] {
    width: 100%!important;
    @media(min-width: $screen-sm-min) {
      width: 20%!important;
    }
  }

  div[class="billing.name.first"], div[class="billing.name.last"] {
    width: 100%!important;
    @media(min-width: $screen-sm-min) {
      width: 40%!important;
    }
  }

  div[class="billing.address.city"] {
    width: 100%!important;
    @media(min-width: $screen-sm-min) {
      width: 50%!important;
    }
  }

  div[class="billing.address.state"], div[class="billing.address.zip"] {
    width: 100%!important;
    @media(min-width: $screen-sm-min) {
      width: 25%!important;
    }
  }

  .cardholder_name, .card_number {
    width: 100%!important;
    @media(min-width: $screen-sm-min) {
      width: 50%!important;
    }
  }

  .card_type {
    width: 100%!important;
    @media(min-width: $screen-sm-min) {
      width: 25%!important;
    }
  }

  .card_expiration {
    width: 100%!important;
    @media(min-width: $screen-sm-min) {
      width: 30%!important;
    }
  }

  button {
    max-width: 100%;
  }


}

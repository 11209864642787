.joinUsBox {
  position: relative;
  min-height: 180px;
  text-align: center;
  padding-top: 35px;
  border-bottom: 1px solid $gray3;

  &:nth-child(4) {
    @media (min-width: $screen-lg-min) { border-bottom: none; }
  }

  &:nth-child(5) {
    @media (min-width: $screen-sm-min) { border-bottom: none; }
  }

  &:nth-child(6) { border-bottom: none; }

  @media (min-width: $screen-sm-min) {
    display: inline-block;
    vertical-align: top;
    margin-right: -0.25em;
    width: (100%/2);

    height: 250px;
    padding-top: 0;

    &:nth-of-type(2n) {
      &:before {
        display: none;
      }
    }
  }
  @media (min-width: $screen-md-min) {
    display: inline-block;
    margin-right: -0.25em;
    width: (100%/3);
    &:nth-of-type(2n) {
      &:before {
        display: inline-block;
      }
    }
    &:nth-of-type(3n) {
      &:before {
        display: none;
      }
    }
  }
  &:before {
    @media (min-width: $screen-sm-min) {
      display: inline-block;
      position: absolute;
      content: '';
      right: 0;
      top: 5%;
      width: 1px;
      height: 90%;
      background-color: $gray3;
    }
  }

  &-wrap {
    @media (min-width: $screen-sm-min) {
      transform: translateY(-50%);
      position: relative;
      top: 50%;
    }
  }

  &-image {
    margin-bottom: 15px;
  }
  &-heading {
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 8px;
    margin-right: auto;
    margin-left: auto;
    width: 80%;
    @media (min-width: $screen-sm-min) {
      font-size: 26px;
      line-height: 32px;
      margin-bottom: 17px;
    }

    @media (min-width: $screen-md-min) {
      width: 75%;
    }
  }

  &-link {
    @media (min-width: $screen-sm-min) { margin-bottom: 0; }
  }

  &-newsletterField {
    margin-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;

    input[type="text"] {
      background: url(/App_Themes/Efc/img/email_homepage.png) no-repeat 25px 50%;
      background-color: transparent;
      background-color: $white;
      border: 2px solid $gray3;
      border-radius: 20px;
      padding-right: 45px;
      padding-left: 45px;
      text-align: center;
      appearance: none;
      font-size: 14px;
      color:  $black;
      height: 40px;
      width: 100%;
      margin-bottom: 10px;

      @media (min-width: $screen-sm-min) { margin-bottom: 15px; }

      &:focus { outline: 0; }
    }
  }
}

.button {
  $buttonHeight: 36px;
  &:before,&:after {
    font-family: 'fontello';
  }

  &:focus {
    color: inherit;
    outline: none;
  }

  &--noBorder {
    border: 0;
    &LTR {
      border-left: 0;
      border-top: 0;
      border-right: 0;
    }
  }

  &--transparent {
    background-color: transparent;
  }

  &--rounded {
    transition: all $basicTransitionDuration $basicTransitionTiming;
    font-family: $font-family-sans-serif;
    border-radius: $buttonHeight/2;
    text-transform: uppercase;
    padding: 11px 25px 0 25px;
    height: $buttonHeight;
    font-weight: 700;
    font-size: 11px;
  }

  &-basic {
    display: inline-block;
    padding: 15px 24px;
    color: $white;
    font-size: 13px;
    font-weight: 700;
    border-radius: 3px;
    text-transform: uppercase;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    &--short {
      padding: 10px 24px;
    }
    &--noMarginBottom {
      @media (min-width: $screen-sm-min) {
        margin-bottom: 0px;
      }
    }
  }

  &-arrowed {
    display: inline-block;
    background-color: $gray1;
    height: 60px;
    width: 100%;
    outline: 0;
    border: 0;
    color: $black;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 10px;
    &:after {
      display: inline-block;
      font-family: 'fontello';
      content: '\e806';
      transition: transform $basicTransitionDuration;
      margin-left: 5px;
    }
    &--active {
      &:after {
        transform: rotate(180deg);
      }
    }
  }

  &-chevron {
    &:after {
      display: inline-flex;
      content: '\e805';
      justify-content: center;
      align-items: center;
      color: $black;
      border-radius: 100%;
      width: 36px;
      height: 36px;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
      background-color: #fff;
      font-size: 13px;
    }
  }

  &-plus {
    &:before {
      display: inline-block;
      content: '\e800';
      font-size: 7px;
      font-weight: 400;
      color: $navyBlue;
    }
  }

  &-download {
    &:before {
      display: inline-block;
      content: '\e81f';
      font-size: 14px;
      font-weight: 400;
      color: $black;
      padding-right: 5px;
    }
    &--white {
      &:before{
        color:$white;
      }
      &:hover{
        &:before{
          color: $black;
        }
      }
    }
  }


  &--expand {
    width: 100%;
    max-width: 261px;
  }

  &--navyBlue {
    background-color: $navyBlue;
    color: $white;
   &:active, &:visited, &:focus {
     color: $white;
   }
    &:hover {
      background-color: $gray3;
      color: $black;
    }
  }
  &--gray {
    background-color: $gray2;
    color: $black;

    &:hover {
      background-color: $navyBlue;
      color: $white;
    }
  }
}

.blackout {
  transition: opacity $basicTransitionDuration $basicTransitionTiming;
  background-color: rgba(0,0,0,.5);
  visibility: hidden;
  position: fixed;
  cursor: pointer;
  z-index: 2;
  opacity: 0;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  &.active {
    visibility: visible;
    opacity: 1;
  }
}

.topicHeading {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  @media (min-width: $screen-sm-min) {
    justify-content: space-between;
  }
  .headers-2 {
    margin: 0;
    margin-bottom: 10px;
  }
  &-right {
    margin-bottom: 10px;
  }
}

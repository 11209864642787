.boxSectionHeader {
  font-family: $font-family-sans-serif;
  background-color: $gray1;
  border: 5px solid $gray3;
  padding-bottom: 27px;
  border-bottom: none;
  text-align: center;
  padding-top: 23px;
  font-weight: 500;
  font-size: 18px;
  color: $black;

  &-sideBorder { border-top: none; }

  &--persons { border-color: $themePurple; }

  &--family { border-color: $themePurple; }

  &--hands { border-color: $themeGreen; }

  &--bird { border-color: $themeYellow; }

  &--cross { border-color: $themeBlue; }

  &--heart { border-color: $themeRed; }
}

.wysiwyg {
  word-wrap: break-word;;
  margin-bottom: 20px;
  &--alone {
    margin-bottom: 30px;
  }
  font-family: $font-family-sans-serif;
  font-size: 16px;

  h1, h2, h3, h4, h5, h6 {
    font-family: $font-family-serif;
    font-weight: 400;
    color: $black;
    margin: 0;
    a {
      color: inherit;
      font-weight: inherit;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  h1 {
    font-size: 36px;
    margin-bottom: 12px;
    line-height: 42px;
    @media (min-width: $screen-md-min) {
      font-size: 42px;
      margin-bottom: 24px;
      line-height: 54px;
    }
  }

  h2 {
    font-size: 30px;
    line-height: 26px;
    margin-bottom: 12px;
    @media (min-width: $screen-md-min) {
      font-size: 30px;
      line-height: 32px;
      margin-bottom: 18px;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 8px;
    @media (min-width: $screen-md-min) {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 12px;
    }
  }

  h4 {
    font-size: 20px;
    @media (min-width: $screen-md-min) {
      font-size: 20px;
    }
  }

  p {
    font-family: $font-family-sans-serif;
    font-size: 16px;
    color: $black;
    margin: 0;
    margin-bottom: 23px;
  }

  ul {
    margin-bottom: 23px;
    li {
      &+li {
        margin-top: 15px;
      }
    }
  }

  blockquote {
    margin-left: 16px;
    padding-left: 28px;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    border-left: 3px solid $black;
    @media (min-width: $screen-md-min) {
      margin-left: 20px;
      padding-left: 34px;
      font-size: 20px;
      line-height: 32px;
    }
  }

  a {
    text-decoration: none;
    color: $blue;
    font-weight: 600;
    &:hover {
      color: $black;
      text-decoration: none;
    }
    &:visited, &:active, &:focus {
      text-decoration: none;
    }
  }
  time {
    font-family: $font-family-serif;
    color:  #333333;
    font-size: 16px;
    font-weight: 400;
  }
  img {
    display: block;
    margin: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
    width: auto !important;
    height: auto !important;
  }

  &--imagesInline {
    img {
      float: left;
      padding: 15px 15px 15px 0px;
    }

  }

  &--margin-bottom {
    margin-bottom: 30px;
  }

  &--biography {
    padding-top: 10px;
    img {
      padding-left: 0;
      margin-top: 0
    }
  }
}

.postList {
  @media (min-width: $screen-sm-min) {
    width: calc(100% - 240px);
    float: left;
  }
  &--tablet {
    @media (min-width: $screen-md-min) {
      width: calc(100% - 240px);
      float: left;
    }
  }
  &--clear {
    float: none;
    width: 100%;
  }
  &-dispatchesFromBrian {
    width: 100%;
  }
}

.bodySearch {
  $that: '.bodySearch';

  &.withMargin { margin-bottom: 40px; }

  &.withShadow { box-shadow: $BoxShadowCenter; }

  @media (min-width: $screen-sm-min) {
    flex: 0 0 145px;
    display: flex;
    align-items: center;
  }
  &-heading {
    display: flex;
    align-items: center;
    padding: 25px;
    height: 30px;

    @media (min-width: $screen-sm-min) {
      height: 50px;
    }

    @media (min-width: $screen-md-min) { flex: 0 0 145px; }
    span {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 700;
    }
  }
  &-search {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    height: 60px;
    background-color: $gray1;
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
    @media (min-width: $screen-sm-min) {
      flex: 1;
    }
    label {
      display: none;
    }
    input[type="text"] {
      background-color: #fff;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: 2px solid $gray3;
      border-right: 0;
      height: 40px;
      width: 100%;
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
      padding-left: 20px;
      font-family: $font-family-sans-serif;
      font-size: 13px;
      color: $black;
      &:focus {
        outline: none;
      }
    }
    input[type="submit"] {
      position: relative;
      background-color: transparent;
      color: $white;
      height: 40px;
      width: 45px;
      text-indent: -99px;
      border-radius: 3px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      outline: 0;
      border: 0;
      z-index: 1;
      transition: color $basicTransitionDuration $basicTransitionTiming;
      @media (min-width: $screen-sm-min) {
        width: 145px;
        text-indent: 0;
        padding-right: 25px;
        padding-left: 50px;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
      }

      &:hover {
        color: $black;

        &+.searchIcon {
          background-color: $gray3;

          &:before { color: $black; }
        }
      }
    }

    .searchIcon {
      transition: all $basicTransitionDuration $basicTransitionTiming;
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
      background-color: $navyBlue;
      position: absolute;
      height: 40px;
      width: 40px;
      right: 15px;
      top: 10px;
      z-index: 0;

      @media (min-width: $screen-sm-min) { width: 131px; }

      &:before {
        transition: all 0.25s ease;
        font-family: 'fontello';
        position: absolute;
        content: '\e802';
        font-size: 18px;
        color: $white;
        left: 11px;
        top: 8px;

        @media (min-width: $screen-sm-min) { left: 20px; }
      }
    }
  }

  &--borderVersion {
    border: 2px solid $gray3;
    padding-bottom: 10px;
    border-radius: 8px;
    padding-top: 10px;

    #{$that} {
      &-heading {
        @media (max-width: $screen-xs-max) {
          padding-bottom: 10px;
          padding-left: 0;
          height: 40px;
        }

        > span {
          @media (max-width: $screen-xs-max) {
            margin-right: auto;
            margin-left: auto;
          }

        }
      };

      &-search { background-color: transparent; }
    }
  }

  &--again {
    background-color: $whitesmoke;
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

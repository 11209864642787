.homeCarousel {
  position: relative;
  // @include radial-shadow(80%);
  @media (min-width: $screen-sm-min) {
    @include make-md-column(12);
  }
  &-wrap {
    display: none;

    &.slick-initialized {
      display: block;
    }

    position: relative;
    background-color: $gray1;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

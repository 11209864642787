.trigram {
  background-color: $navyBlue;
  padding: 19px 15px;
  cursor: pointer;
  height: 59px;
  width: 59px;

  @media (min-width: $screen-sm-min) {
    padding: 25px 15px;
    height: 79px;
    width: 79px;
  }

  &-line {
    transition: transform $basicTransitionDuration $basicTransitionTiming;

    div {
      background-color: $white;
      height: 3px;
      width: 100%;
    }

    &--first,
    &--second {
      margin-bottom: 6px;

      @media (min-width: $screen-sm-min) { margin-bottom: 10px }
    }
  }
}

.action {
  .trigram {
    &-line {
      transform: rotate(225deg);

      &--first {
        transform: rotate(90deg);
        margin-bottom: -3px;
        margin-top: 9px;

        @media (min-width: $screen-sm-min) { margin-top: 13px; }
      }

      &--second { visibility: hidden; }

      &--third {
        margin-top: -9px;
        @media (min-width: $screen-sm-min) { margin-top: -13px; }
      }
    }
  }
}

.decoratedSelect {
  display: inline-block;
  padding-bottom: 30px;
  position: relative;

  @media (min-width: $screen-sm-min) { padding-bottom: 0; }

  span {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    padding-right: 10px;
    padding-left: 15px;
    padding-bottom: 5px;

    @media (min-width: $screen-sm-min) {
      padding-bottom: 0;
      padding-left: 0;
    }
  }
  select {
    font-family: $font-family-serif;
    font-size: 15px;
    border-radius: 20px;
    border: 2px solid $gray3;
    height: 36px;
    padding-left: 20px;
    padding-right: 50px;
    appearance: none;
    background-color: transparent;
    cursor: pointer;

    @media (max-width: $screen-xs-max) { width: 100%; }
    
    &:focus {
      outline: 0;
    }
  }

  &--fullWidth {
    select { width: 100%; }
  }

  &--text {
    select {
      font-family: $font-family-sans-serif;
      font-size: 14px;
    }
  }
}

.videoBottomSectionContainer {
  text-align: center;

  &-Box {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
    margin-bottom: 30px;

    h2 {
      font-size: 28px;
      padding: 20px 0px;
      margin-bottom: 0px;
    }

    p {
      line-height: 24px;
      padding: 0px 15px;

      @media (min-width: $screen-sm-min) {
        min-height: 120px;
      }

      @media (min-width: $screen-lg-min) {
        min-height: 100px;
      }

    }

    img {
      width: 100%;
    }
  }

}

.journals {
  &-list {
    display: flex;
    flex-flow: row wrap;
    margin-top: -10px;
    margin-bottom: 20px;
    @media (min-width: $screen-sm-min) {
      margin-left: -15px;
    }
    .justify-helper {
      display: flex;
      justify-content: flex-end;
    }
    .carouselBox {
      margin-top: 30px;
    }
    .button {
      margin: 0;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}
.searchResult {
  padding: 20px 0px;
  border-bottom: 1px solid $gray3;

  &-header {
    color:  $black;
    font-size: 26px;
    font-weight: 400;
  }

  &-paragraph {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
  }

  &-link {
    word-wrap: break-word;
    font-size: 14px;
  }

  span.searchHighlight { background-color: $beige; }
}

.eventsCalendar {
  $blue: #c4e1f5;
  $darkBlue: #a4c6eb;
  $green: #ebf5c4;
  $darkGreen: #c7e6a1;
  $beige: #e6dcb8;
  $darkBeige: #d1c492;

  padding: 0 15px 20px;
  background: $whitesmoke;
  margin-top: 20px;
  &-filter {
    display: flex;
    overflow: visible;
    background: $white;
    margin: 0 -15px;
    padding-bottom: 20px;
    h4 {
      color: $black;
      font-size: 12px;
      text-transform: uppercase;
      font-family: $font-family-sans-serif;
      font-weight: 700;
      margin-right: 20px;
      line-height: 26px;
    }
    > div {
      margin-right: 25px;
    }
    label {
      &::before {
        width: 11px;
        height: 11px;
        content: '';
        display: inline-block;
        margin-right: 5px;
      }
    }
    &--green {
      &::before {
        background: #a9d672;
      }
    }
    &--beige {
      &::before {
        background: #b1a36f;
      }
    }
    &--blue {
      &::before {
        background: #5a7da1;
      }
    }
  }
  &-setup {
    display: none !important;
  }
  &-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    button {
      appearance: none;
      border: 0;
      height: 50px;
      width: 40px;
    }
  }
  h2 {
    margin: 0;
    font-size: 26px;
  }
  &-table {
    display: table;
    table-layout: fixed;
    width: 100%;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  }
  &-header {
    display: table-header-group;
    span {
      font-size: 13px;
      font-weight: 700;
      line-height: 45px;
      text-align: center;
      border-top: 0;
    }
  }
  &-row {
    display: table-row;
  }
  &-cell {
    border-top: 1px solid $gray2;
    display: table-cell;
    background: $white;
    padding: 0 15px;
    &:not(:first-child) {
      border-left: 1px solid $gray2;
    }
    &-content {
      display: flex;
      flex-flow: row wrap;
      position: relative;
      margin: 0 -15px;
      width: calc(100% + 30px);
      padding: 0 15px;
      min-height: 80px;
    }
    &--inactive {
      background: $whitesmoke;
      .eventsCalendar-day {
        color: $gray4;
      }
    }
  }
  &-day {
    font-family: $font-family-serif;
    font-size: 20px;
    line-height: 32px;
    border-bottom: 1px solid $gray2;
    display: block;
    margin: 0 -15px;
    padding: 0 15px;
  }
  &-event {
    font-size: 11px;
    line-height: 16px;
    padding: 0 4px;
    margin: 15px 0 20px;
    max-width: 100%;
    position: relative;
    a {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    b {
      font-weight: 600;
      text-transform: uppercase;
      display: block;
      margin: 0 -4px;
      padding: 0 4px;
    }
    &--beige:not(&--daily) {
      border-left: 0;
      background: $beige;
      b {
        background: $darkBeige;
      }
    }
    &--blue:not(&--daily) {
      border-left: 0;
      background: $blue;
      b {
        background: $darkBlue;
      }
    }
    &--green:not(&--daily) {
      border-left: 0;
      background: $green;
      b {
        background: $darkGreen;
      }
    }
    &--green {
      border-left: 4px solid $darkGreen;
    }
    &--blue {
      border-left: 4px solid $darkBlue;
    }
    &--beige {
      border-left: 4px solid $darkBeige;
    }
    &--continuous-start, &--continuous-end {
      position: relative;
      width: calc(100% + 15px);
      max-width: calc(100% + 15px);
    }
    &--continuous {
      position: relative;
      margin-left: -15px;
      margin-right: -15px;
      width: calc(100% + 30px);
      max-width: none;
    }
    &--continuous-start {
      margin-right: -15px;
    }
    &--continuous-end {
      margin-left: -15px;
    }
  }

  + .helpers-textCenter {
    margin-top: 20px;
  }
}

.header {
  &-logo {
    @media (max-width: $screen-md-max) {
      position: absolute;
      z-index: 2;
      top: 3px;
      left: 0;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) { top: 6px; }

    @media (min-width: $screen-lg-min) {
      @include inlineBlock(top);
      margin-top: 20px;
    }

    &--small,
    &--big { padding: 10px; }

    &--small {
      width: 220px;

      @media (min-width: $screen-sm-min) { display: none; }
    }

    &--big {
      display: none;
      width: 320px;

      @media (min-width: $screen-sm-min) { display: block; }
    }
  }
}

.fixedHeader {
  .header {
    &-logo {
      margin-top: 4px;

      &--small { display: block; }

      &--big { display: none; }
    }
  }
}

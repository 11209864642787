.footer {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
  &-menu {
    @media (min-width: $screen-md-min) {
      display: flex;
      justify-content: space-between;
    }
  }
  &-heading {
    color:  $black;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    margin-bottom: 27px;
    @media (min-width: $screen-md-min) {
      text-align: left;
    }
  }
  &-publication {
    display: block;
    @media (max-width: $screen-md-max) {
      margin-left: auto;
      margin-right: auto;
    }
    margin-bottom: 10px;

    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
      @include inlineBlock;
    }
  }
  &-right {
    text-align: center;
    @media (min-width: $screen-md-min) {
      text-align: right;
    }
  }
  &-smallMenu {
    display: inline-block;
    ul {
      display: inline-block;
      list-style: none;
      padding: 0;
      margin: 0;
    }
    li {
      display: inline-block;
      border-right: 1px solid $gray3;
      &:last-of-type {
        border-right: 0;
        a {
          padding-right: 0;
        }
      }
    }
    a {
      display: inline-block;
      color:  #a6a6a6;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  &-credits {
    display: block;
    text-align: center;
    color:  $gray4;
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
    margin-top: 26px;
    @media (min-width: $screen-md-min) {
      text-align: left;
    }
  }
  &-logo {
    display: block;
    text-align: center;
    margin-top: 20px;
    @media (min-width: $screen-md-min) {
      margin-top: 0;
      text-align: left;
    }
  }
}

.downloadButtons {
  position: relative;
  padding-top: 30px;
  text-align: center;

  &--withMargin {
    .button {
      &:first-of-type {
        margin-right: 15px;
      }
    }
  }
}

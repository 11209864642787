/*
Example of use:

<div class="topicDecorator topicDecorator--TOPIC_THEME">
  <label>Topic:</label>
  <a href="#">TOPIC_NAME</a>
</div>
*/

.topicDecorator {
  a,
  span,
  label {
    font-family: $font-family-sans-serif;
    // @include inlineBlock(middle);
    display: inline-block;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 11px;
  }

  span,
  label {
    vertical-align: middle;
    padding-right: 5px;
    color: $gray4;
  }

  a {
    transition: background-color $basicTransitionDuration $basicTransitionTiming;
    padding: 2px 5px 1px 5px;
    border-radius: 3px;

    @media (max-width: $screen-xs-max) { max-width: calc(100% - 45px); }
  }

  &--hands {
    a {
      background-color: $themeGreen;
      color: $white;

      &:hover { background-color: $themeGreenOpacity; }
    }
  }

  &--inline {
    @include inlineBlock;
    margin-right: 20px;
  }

  &--cross {
    a {
      background-color: $themeBlue;
      color: $white;

      &:hover { background-color: $themeBlueOpacity; }
    }
  }

  &--persons {
    a {
      background-color: $themePurple;
      color: $white;

      &:hover { background-color: $themePurpleOpacity; }
    }
  }

  &--bird {
    a {
      background-color: $themeYellow;
      color: $black;

      &:hover { background-color: $themeYellowOpacity; }
    }
  }

  &--heart {
    a {
      background-color: $themeRed;
      color: $white;

      &:hover { background-color: $themeRedOpacity; }
    }
  }
}

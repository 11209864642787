.themeBackgroundColor {
  &-- {
    border-color: $brown !important;
  }
  &--persons { background-color: $themePurple !important; }

  &--hands { background-color: $themeGreen !important; }

  &--bird { background-color: $themeYellow !important; }

  &--cross { background-color: $themeBlue !important; }

  &--heart { background-color: $themeRed !important; }
}

.singleVideo {
  $that: '.singleVideo';
  border-bottom: 1px solid $gray3;
  padding: 20px 0px;

  &-image {
    margin-bottom: 10px;


    @media (min-width: $screen-sm-min) {
      @include inlineBlock(top);
      padding-right: 15px;
      width: 165px;
    }

    a {
      display: block;

      img { width: 100%; }
    }

    &--withHeight {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 150px;
      @media(min-width: $screen-sm-min) {
        height: 78px;
      }
      @media(min-width: $screen-md-min) {
        height: 67px;
      }
    }
  }

  &-content {
    @media (min-width: $screen-sm-min) {
      @include inlineBlock(top);
      width: calc(100% - 165px);
      margin-top: -5px;
    }

    .singleVideoTitle {
      font-family: $font-family-serif;
      font-size: 17px;
      color: $black;

      &:hover { color: $blue; }
    }

    .singleVideoDate {
      padding: 10px 0px;
      font-weight: 500;
      color: $darkgray;
      font-size: 13px;
      display: block;
    }
  }

  &.withTopicDecorator {
    padding-bottom: 8px;

    #{$that} {
      &-image {
        border-right: 3px solid $brown;
        margin-right: 15px;
        position: relative;
        padding-right: 0;

        @media (min-width: $screen-lg-min) { width: 120px; }

        .themeDecoration {
          transition: background-color $basicTransitionDuration $basicTransitionTiming;
          transform: translateY(-50%);
          position: absolute;
          font-size: 15px;
          height: 30px;
          right: -16px;
          width: 30px;
          top: 50%;

          @media (min-width: $screen-lg-min) {
            font-size: 13px;
            height: 25px;
            right: -14px;
            width: 25px;
          }
        }
      }

      &-content {
        @media (min-width: $screen-sm-min) { width: calc(100% - 181px); }

        @media (min-width: $screen-lg-min) { width: calc(100% - 136px); }

        .singleVideoTitle {
          @media (min-width: $screen-lg-min) { font-size: 16px; }
        }
      }
    }

    .topicDecorator {
      display: none; }
  }
}

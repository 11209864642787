.termsOfUse {
  padding: 20px 0 40px;
  counter-reset: section;

  .background {
    height: 300px;
    background-size: cover;
    @media (min-width: $screen-md-min) {
      height: 443px;
    }
  }

  .icons-print {
    margin-left: auto;
    margin-right: 15px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
    border-radius: 50%;

    @media (max-width: $screen-xs-max) {
      margin-left: -15px;
      margin-bottom: 15px;
    }
  }

  .content {
    padding: 0 25px;
    @media (min-width: $screen-md-min) {
      padding: 0 35px
    }
  }

  h2 {
    margin-top: 20px;
    margin-bottom: 0;
    &::before {
      counter-increment: section;
      content: counter(section) ". ";
    }
  }

  p, ul {
    line-height: 25px;
    font-size: 14px;
    margin-bottom: 0;
  }

  p {
    margin-top: 22px;
  }

  li {
    margin-top: 10px;
  }

  ul {
    list-style-type: lower-latin;
    ul {
      list-style-type: lower-roman;
      margin-top: 18px;
      margin-bottom: 10px;
    }
  }
}

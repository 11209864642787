.photoGallery {
  margin-bottom: 30px;
  &-box {
    margin-bottom: 30px;
  }

  &-image {
    height: 260px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  img {
    max-width: 100%;
  }

  a {
    height: 100%;
    width: 100%;
    display: block;
    cursor: zoom-in;
  }
}

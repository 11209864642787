.themeBorderColor {
  &-- {
    border-color: $brown !important;
  }
  &--persons { border-color: $themePurple !important; }

  &--hands { border-color: $themeGreen !important; }

  &--bird { border-color: $themeYellow !important; }

  &--cross { border-color: $themeBlue !important; }

  &--heart { border-color: $themeRed !important; }
}

.stayConnected {
  display: inline-block;
  text-align: center;
  margin-bottom: 30px;
  @media (min-width: $screen-md-min) {
    text-align: right;
    width: 100%;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    font-family: $font-family-sans-serif;
    color: $black;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    @media (min-width: $screen-md-min) {
      display: inline-block;
      margin-right: 30px;
      margin-bottom: 0;
    }
  }
  ul {
    display: inline-block;
    vertical-align: middle;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  a {
    &:before {
      font-family: 'fontello';
      font-size: 32px;
      margin-left: 7px;
    }
    &:hover {
      &:before {
        opacity: 0.8;
      }
    }
  }
}

.breadcrumbs {
  text-transform: uppercase;
  margin-bottom: 16px;
  font-weight: 600;
  margin-top: 6px;
  font-size: 11px;

  a {
    color: $gray4;

    &:hover { color: $black; }
  }

  span {
    padding-right: 2px;
    padding-left: 2px;

    &:not(:last-of-type) { vertical-align: text-bottom; }
  }

  .CMSBreadCrumbsCurrentItem { color: $black; }
}

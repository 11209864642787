.homeSlider {
  display: none;

  &.slick-initialized { display: block; }

  @media (min-width: $screen-lg-min) {
    @include container-fixed;
    width: $container-lg;
  }
  &-slide {

  }
  &-wrap {
    @media (min-width: $screen-lg-min) {
      display: flex;
      align-items: center;
    }
  }
  &-slideImage {
    position: relative;
    border-bottom: 5px solid $themeYellow;
    margin-bottom: 30px;

    .themeDecoration {
      position: absolute;
      bottom: -26.5px;
      left: 0;
      right: 0;
      margin: auto;
      width: 48px;
      height: 48px;
      font-size: 48px;
    }

    @media (min-width: $screen-md-min) {
      margin-bottom: 40px;
    }
    @media (min-width: $screen-lg-min) {
      flex: 0 0 690px;
      margin-bottom: 0;
      border-bottom: 0;
      border-right: 5px solid $themeYellow;
      .themeDecoration {
        left: auto;
        bottom: auto;
        right: -26px;
        top: 50px;
      }
      &:focus {
        outline: 0;
      }
    }
    img {
      width: 100%;
    }
  }
  &-content {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    @media (min-width: $screen-lg-min) {
      text-align: left;
      padding-left: 40px;
      padding-right: 40px;
    }
    h3 {
      color: $black;
    }
    p { font-size: 16px; }
  }
  .slick-dots {
    position: static;
    display: flex;
    border-top: 1px solid $gray3;
    border-bottom: 1px solid $gray3;
    li {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 20%;
      min-height: 70px;
      height: auto;
      padding: 0;
      margin: 0;
      @media (min-width: $screen-sm-min) {
        min-height: 107px;
        height: auto;
      }
      @media (min-width: $screen-md-min) {
        min-height: 97px;
      }
      @media (min-width: $screen-lg-min) {
        &:hover {
          h4 { color: $black; }
        }
      }
      &.slick-active {
          border-top: 6px;
          border-style: solid;
          border-bottom: 0;
          border-left: 0;
          border-right: 0;
        .themeDecoration {
          position: relative;
          top: -3px;
        }
        h4 {
          position: relative;
          top: 7px;
          color: $black;
        }
        &:after {
          margin-top: -3px;
        }
      }
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        right: 0;
        width: 1px;
        height: 60px;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: $gray3;;
        @media (min-width: $screen-sm-min) {
          height: 97px;
        }
        @media (min-width: $screen-md-min) {
          height: 80px;
        }
      }
      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }
    .themeDecoration {
      margin-left: auto;
      margin-right: auto;
      width: 30px;
      height: 30px;
      font-size: 28px;
    }
    h4 {
      display: none;
      @media (min-width: $screen-sm-min) {
        display: block;
        position: relative;
        top: 10px;
        font-size: 13px;
        padding-left: 5px;
        padding-right: 5px;
        color: $gray4;
        text-transform: uppercase;
        font-weight: 700;
        font-family: $font-family-sans-serif;
        line-height: 16px;
      }
      @media (min-width: $screen-lg-min) {
        transition: color $basicTransitionDuration $basicTransitionTiming;
      }
    }
  }
}

.menu-open {
  overflow-y: hidden;

  .blackout {
    visibility: visible;
    opacity: 1;
  }

  .categories { right: 0; }
}

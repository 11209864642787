.searchFilterBox {

  &-body {
    position: relative;
    padding-left: 15px;
  }

  &-header {
    font-family: $font-family-sans-serif;
    color: $black;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding: 20px 10px;
    border-bottom: 1px solid $gray2;
  }

  &-optionsContainer {
    border-bottom: 1px solid $gray2;
    padding-bottom: 20px;
  }

  &-options {

    max-height: 150px;
    overflow-y: scroll;

    &--noScroll {
      max-height: initial;
      overflow-y: initial;
    }

    div {
      margin-bottom: 10px;
    }

    input[type="checkbox"] {
      appearance: none;
      border: 1px solid $gray3;
      width: 21px;
      height: 21px;
      vertical-align: middle;
      margin-right: 5px;
      cursor: pointer;

      &:checked {
        background-image: url(../img/checkMark.png);
        background-repeat: no-repeat;
        background-position: center;

        & + label {
          font-weight: 600;
        }
      }

    }

    label {
      color: $darkgray;
      font-size: 13px;
      font-weight: 400;
      vertical-align: middle;
    }
  }


  &-clearLabel {
    border-radius: 15px;
    font-size: 13px;
    font-weight: 500;
    margin: 20px 0px;
    display: inline-block;
    padding: 5px 10px 5px 25px;
    background-color: $gray1;
    background-image: url(../img/clearIcon.png);
    background-repeat: no-repeat;
    background-position: 8% 45%;
    cursor: pointer;
  }
}

.searchAgainBox {
  width: 100%;
  background-color: $gray1;
  padding: 30px 20px;
  margin-bottom: 20px;

  &-label {
    color:  $black;
    font-size: 13px;
    font-weight: 700;
    display: block;
    margin-bottom: 0px;
    text-transform: uppercase;
    margin-bottom: 10px;
    padding-right: 20px;
    @media (min-width: $screen-sm-min) {
      display: inline-block;
      margin-bottom: 0px;
    }
  }

  input:focus {
    outline: none;
  }

  input[type="text"] {
    border: 2px solid $gray3;
    height: 40px;
    border-radius: 20px;
    padding-left: 15px;
    margin-bottom: 10px;
    display: block;
    @media (min-width: $screen-sm-min) {
      display: inline-block;
      margin-bottom: 0px;
      width: 50%;
    }
    @media (min-width: $screen-md-min) {
      width: 60%;
    }
    @media (min-width: $screen-lg-min) {
      width: 70%;
    }
  }

  input[type="submit"] {
    border-radius: 3px;
    background-color: $navyBlue;
    height: 40px;
    color:  $white;
    font-size: 13px;
    font-weight: 700;
    border: none;
    text-transform: uppercase;
    width: 128px;
    padding-top: 3px;
    padding-left: 25px;
    background-image: url(../img/searchIcon.png);
    background-repeat: no-repeat;
    background-position: 20% 50%;
    @media (min-width: $screen-sm-min) {
      margin-bottom: 0px;
      margin-left: -20px;
    }
  }
}

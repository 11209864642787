.postTeaser {
  position: relative;
  margin-right: 15px;
  margin-bottom: 55px;
  &:after {
    position: absolute;
    right: 0;
    top: 0;
    display: inline-block;
    content: '';
    height: 100%;
    width: 5px;
    border-radius: 100px;
    z-index: -1;
  }
  .themeBox {
    right: -16px;
    z-index: 1;
  }
  &-date {
    font-family: $font-family-serif;
    display: block;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 16px;
    color: $black;
  }
  &-header {
    transition: color $basicTransitionDuration $basicTransitionTiming;
    font-family: $font-family-serif;
    @include inlineBlock;
    padding-right: 45px;
    margin-bottom: 20px;
    position: relative;
    padding-left: 15px;
    font-size: 24px;
    cursor: pointer;
    color: $black;

    &:hover { color: $navyBlue; }

    &:before {
      position: absolute;
      left: 0;
      display: inline-block;
      content: '';
      height: 100%;
      width: 5px;
      border-radius: 2px;
      margin-right: 5px;
    }
    @media (min-width: $screen-sm-min) {
      font-size: 28px;
    }
  }
  &-description {
    font-size: 16px;
    padding-right: 15px;
    margin-bottom: 6px;
    @media (min-width: $screen-sm-min) {
      padding-right: 35px;
    }
  }
  &-tags {
    margin-bottom: 6px;
    span {
      display: inline-block;
      padding-right: 10px;
      text-transform: uppercase;
      color: $gray4;
      font-size: 11px;
    }
    a {
      display: inline-block;
      text-transform: uppercase;
      font-size: 11px;
    }
  }
  // Themes
  &- {
    &:after,.postTeaser-header:before { background-color: $brown; }
  }
  &-hands {


    &:after,.postTeaser-header:before { background-color: $themeGreen; }
  }

  &-cross {


    &:after,.postTeaser-header:before { background-color: $themeBlue; }
  }

  &-persons {


    &:after,.postTeaser-header:before { background-color: $themePurple; }
  }

  &-bird {


    &:after,.postTeaser-header:before { background-color: $themeYellow; }
  }

  &-heart {


    &:after,.postTeaser-header:before { background-color: $themeRed; }
  }

  &-family {

    &:after,.postTeaser-header:before { background-color: $themePurple; }
  }

  &-dispatchesFromBrian {
    margin-top: 30px;
    margin-bottom: 0;
    display: inline-block;
    &:after {
      display: none;
    }
    .link-more {
      float: right;
    }
    + .helpers-textCenter {
      margin-top: 25px;
    }
  }

  .link-more {
    margin-bottom: 5px;
    margin-top: 20px;
  }
}

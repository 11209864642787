.boxWithBorderTop {
  position: relative;
  background-color: $gray1;
  padding: 35px 15px;
  border-top: 4px solid $brown;
  margin-bottom: 30px;
  text-align: center;

  &:before {
    display: inline-block;
    text-align: center;
    line-height: 34px;
    font-family: 'fontello';
    background-color: $brown;
    width: 36px;
    height: 36px;
    content: '\e80c';
    color: white;
    border-radius: 100%;
    position: absolute;
    top: -18px;
    left:0;
    right: 0;
    margin: auto;
    font-size: 21px;
  }
}

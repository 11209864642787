.mfp-container, .mfp-content, .popup-lightbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-lightbox {
  flex-flow: column nowrap;
  iframe {
    order: 1;
  }
}

.mfp-close.efc-icon-x {
  position: relative;
  order: 0;
  color: $white;
  opacity: 1;
  font-size: 24px;
  margin-left: auto;
  width: auto;
  &:active {
    top: 0;
  }
}
.eventDetails {
  position: relative;

  &-table {
    margin-bottom: 30px;
  }

  &-row {
    overflow: auto;
    @media (min-width: $screen-sm-min) {
      border-bottom: 1px solid $gray2;
    }
  }

  &-header {
    color:  $black;
    font-size: 13px;
    font-weight: 700;
    line-height: 50px;
    background-color: $whitesmoke;
    border-left: 5px solid $gray2;
    padding-left: 20px;
    text-transform: uppercase;
    @media (min-width: $screen-sm-min) {
      display: inline-block;
      float: left;
      width: 20%;
    }
  }

  &-cell {
    color:  #5a7da1;
    font-size: 15px;
    font-weight: 500;
    line-height: 50px;
    padding-left: 20px;
    @media (min-width: $screen-sm-min) {
      display: inline-block;
      float: left;
      width: 80%;
    }
  }
}

#eventsDetails {
  .topicIntro {
    margin-bottom: 20px;
  }
}

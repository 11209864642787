.simpleList {
  border: 1px solid $gray2;
  border-top: 3px solid $brown;

  &-title {
    font-family: $font-family-sans-serif;
    border-bottom: 1px solid $gray2;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    padding: 20px;
    color: $black;
  }

  &-content {
  }
}

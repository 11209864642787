.videoThumbnail {
  display: flex;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid $gray3;
  &-image {
    flex: 0 0 105px;
    margin-right: 13px;
    height: 58px;
    background-size: cover;
    a {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    @media (min-width: $screen-sm-min) {
      flex: 0 0 140px;
      height: 78px;
    }
  }
  &-description {
    h5 {
      margin: 0;
      padding: 0;
      font-size: 17px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      margin-bottom: 8px;
    }
  }
}

.headers {
  font-family: $font-family-serif;
  font-size: 26px;

  &--center {
    text-align: center;
  }
  &--desktopLeft {
    @media (min-width: $screen-sm-min) {
      text-align: left !important;
    }
  }
  &--justified {
    justify-content: space-between;
    padding-right: 10px;
    align-items: center;
    display: flex;
  }
  &-1 {
    font-size: 30px;
    line-height: 36px;
    margin-top: 21px;
    margin-bottom: 28px;
    @media (min-width: $screen-sm-min) {
      font-size: 42px;
      line-height: 52px;
    }
  }
  &-2 {
    font-size: 26px;
    margin-bottom: 31px;
    margin-top: 31px;
    @media (min-width: $screen-sm-min) {
      font-size: 36px;
      margin-bottom: 39px;
      margin-top: 39px;
    }
  }
  &-decoration {
    padding-left: 20px;
    margin-bottom: 25px;
    margin-top: 5px;
    @media (min-width: $screen-sm-min) { padding-left: 30px; }
    &--brown {
      border-left: 5px solid $brown;
    }
  }
  &--smallMargin {
    margin-top: 13px;
    margin-bottom: 12px;
  }

  &-date {
    font-family: $font-family-serif;
    margin-bottom: 10px;
    line-height: 1.5em;
    font-weight: 400;
    font-size: 16px;
    display: block;
    color: $black;

    @media (min-width: $screen-sm-min) { font-size: 17px; }
  }

  &-withDrop {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .headers-2, .headers-3 {
        margin-right: auto;
    }
    @media (min-width: $screen-sm-min) {
      flex-direction: row;
    }
    .share {
      margin-bottom: 10px;
    }
    @media (min-width: $screen-sm-min) {
      .share {
        margin-left: auto;
        margin-bottom: 0;
      }
    }

    .icons-print {
      margin-left: auto;
    }

    .decoratedSelect {
      width: 100%;
      @media (min-width: $screen-sm-min) {
        width: auto;
        margin-left: auto;
      }
    }

    &--noMobileFlex {
      display: block;
      margin-bottom: 10px;
      @media (min-width: $screen-sm-min) {
        display: flex;
        margin-bottom: 0px;
      }
    }
  }
}

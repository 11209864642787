.topicsMenu {
  display: none;
  @media (min-width: $screen-md-min) {
    display: block;
  }

  &.solidColours {
    .themeDecoration {
      + a {

      }
      &-persons {
        + a {
          transition: all $basicTransitionDuration $basicTransitionTiming;
          background: $themePurple;
          color: $white;

          &:hover {
            background-color: $gray3;
            color: $black;
          }
        }
      }

      &-bird {
        + a {
          transition: all $basicTransitionDuration $basicTransitionTiming;
          background: $themeYellow;
          color: $white;

          &:hover {
            background-color: $gray3;
            color: $black;
          }
        }
      }

      &-cross {
        + a {
          transition: all $basicTransitionDuration $basicTransitionTiming;
          background: $themeBlue;
          color: $white;

          &:hover {
            background-color: $gray3;
            color: $black;
          }
        }
      }

      &-hands {
        + a {
          transition: all $basicTransitionDuration $basicTransitionTiming;
          background: $themeGreen;
          color: $white;

          &:hover {
            background-color: $gray3;
            color: $black;
          }
        }
      }

      &-heart {
        + a {
          transition: all $basicTransitionDuration $basicTransitionTiming;
          background: $themeRed;
          color: $white;

          &:hover {
            background-color: $gray3;
            color: $black;
          }
        }
      }
    }
  }
  h4 {
    width: 100%;
    height: 70px;
    background-color: $whitesmoke;
    border-radius: 4px 4px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $gray3;
    border-bottom: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    width: 100%;
    height: 70px;
    background: $white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    margin: 10px 0;
    font-size: 19px;
    border-left: 25px solid $whitesmoke;
    position: relative;
  }

  a, span:not(.themeDecoration) {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 30px;
    color: $black;
    font-family: Alice;
    font-weight: 400;
  }

  .themeDecoration-persons, .themeDecoration-persons--active {
    + a:hover, + span {
      background: $themePurple;
      color: #fff;
    }
  }

  .themeDecoration-bird, .themeDecoration-bird--active {
    + a:hover, + span {
      background: $themeYellow;
      color: #fff;
    }
  }

  .themeDecoration-cross, .themeDecoration-cross--active {
    + a:hover, + span {
      background: $themeBlue;
      color: #fff;
    }
  }

  .themeDecoration-hands, .themeDecoration-hands--active {
    + a:hover, + span {
      background: $themeGreen;
      color: #fff;
    }
   }

   .themeDecoration-heart, .themeDecoration-heart--active {
     + a:hover, + span {
       background: $themeRed;
       color: #fff;
     }
    }

  .themeDecoration {
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    position: absolute;
    border: 2px solid $whitesmoke;
  }
}

.topicsPage {
  .topicsMenu {
    margin-top: 20px;
  }
}

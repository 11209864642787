.contactFormWindow {
  background-color: $white;

  &-header,
  &-text {
    padding-right: 30px;
    padding-left: 30px;
  }

  &-header {
    font-family: $font-family-serif;
    padding-bottom: 25px;
    margin-bottom: 20px;
    position: relative;
    padding-top: 20px;
    text-align: left;
    font-size: 30px;
    color: $black;
  }

  &-text {
    line-height: 1.75em;
    text-align: left;
  }

  &-form {
    padding: 25px 30px 120px 30px;
    position: relative;
    background-color: $gray1;
    width: 100%;

    .questions {
      display: table;
    }

    .questions > div {
      display: table-row;

      label,
      input { display: table-cell; }

      input,
      textarea {
        transition: all $basicTransitionDuration $basicTransitionTiming;
        font-family: $font-family-sans-serif;
        border: 2px solid $gray3;
        border-radius: 20px;
        padding-right: 15px;
        padding-left: 15px;
        font-size: 14px;
        color: $black;
        width: 100%;

        &:focus {
          border-color: $gray4;
          outline: none;
        }
      }

      label {
        font-family: $font-family-sans-serif;
        vertical-align: top;
        padding-top: 10px;
        font-weight: 600;
        text-align: left;
        font-size: 13px;
        color: $black;
        width: 110px;
      }

      input {
        margin-bottom: 15px;
        height: 40px;
      }

      textarea {
        padding-top: 15px;
        height: 150px;
        resize: none;
      }
    }

    > div:last-of-type {
      background-color: $white;
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;

      input {
        transition: all $basicTransitionDuration $basicTransitionTiming;
        font-family: $font-family-sans-serif;
        background-color: $navyBlue;
        text-transform: uppercase;
        margin-bottom: 30px;
        border-radius: 4px;
        margin-right: auto;
        text-align: center;
        padding: 15px 120px;
        margin-left: auto;
        font-weight: 700;
        margin-top: 20px;
        font-size: 13px;
        color: $white;
        border: none;

        &:hover {
          background-color: $gray2;
          color: $black;
        }

        &:focus { outline: none; }
      }
    }
  }
}

.updateBox {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;
  position: relative;

  img { width: 100%; }

  h2 { font-size: 22px; }

  p {
    margin-bottom: 0;
    font-size: 14px;
    padding-bottom: 50px;
  }

  &-contentBox { padding: 15px; }

  .share,
  .webinars-links {
    position: absolute;
    bottom: 15px;
  }

  .share { right: 15px; }

  .webinars-links { left: 15px; }
}

.searchQuery {
  text-align: center;
  width: 100%;

  @media (min-width: $screen-sm-min) { text-align: left; }

  &-info {
    color: $black;
    font-size: 16px;
    font-weight: 400;
  }

  &-label {
    background-color: $gray2;
    border-radius: 4px;
    padding: 5px 10px;
    font-weight: 600;
    display: block;
    margin: 8px 0;

    @media (min-width: $screen-sm-min) {
      display: inline-block;
      margin: 0 8px;
    }
  }

  &-place {
    font-weight: 600;
    display: block;
    margin: 8px 0;

    @media (min-width: $screen-sm-min) {
      @include inlineBlock;
      margin: 0;
    }

    .themeDecoration {
      vertical-align: text-top;
      margin-right: 3px;
      font-size: 11px;
      height: 20px;
      width: 20px;
    }
  }
}

.feedbackSection {
  margin-top: 15px;

  h2 {
    padding-bottom: 31px;
    padding-top: 31px;
    margin-bottom: 0;
    margin-top: 0;

    @media (min-width: $screen-sm-min) {
      padding-bottom: 39px;
      padding-top: 39px;
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}

.home {
  &-mainHeading {
    margin-bottom: 35px;
    text-align: center;
    margin-top: 5px;
    font-size: 36px;
  }

  &-joinUs {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

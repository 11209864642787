.divider {
  background-color: $gray3;
  height: 1px;
  width: 100%;
  margin-bottom: 35px;
  &-small {
    width: auto;
    margin-bottom: 25px;
    margin-left: -$grid-gutter-width;
    margin-right: -$grid-gutter-width;
  }
}

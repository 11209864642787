.modalWindow {
  transition: all $basicTransitionDuration $basicTransitionTiming;
  visibility: hidden;
  position: fixed;
  z-index: 15;
  opacity: 1;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  &.showWindow {
    visibility: visible;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
  }

  &-content {
    width: calc(100% - 30px);
    overflow-y: auto;
    margin: auto;
    display: flex;
    align-items: center;
    height: auto;
    position: relative;

    @media (min-width: $screen-sm-min) { width: 600px; }

    .modalWindowCloseButton {
      &:before {
        transition: color $basicTransitionDuration $basicTransitionTiming;
        font-family: 'fontello';
        position: absolute;
        content: '\e81e';
        font-size: 20px;
        cursor: pointer;
        right: 35px;
        z-index: 1;
        top: 30px;
      }

      &:hover { color: $gray4; }
    }
  }
}

.relatedThemes {
  padding-bottom: 15px;
  @media (min-width: $screen-md-min) {
    display: none;
  }
  &-header {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 12px;
    border-bottom: 1px solid $gray2;
    margin-bottom: 15px;
  }
  &-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    @media (min-width: $screen-sm-min) {
      display: inline-block;
      margin-right: 15px;
    }
    a {
      display: block;
      padding-top: 7px;
      padding-bottom: 7px;
      color: $black;
      &:hover {
        opacity: 0.8;
      }
    }
    .themeDecoration {
      margin-right: 10px;
    }
  }
}

.simplePagination {
  padding-bottom: 20px;
  padding-top: 20px;
  position: relative;
  margin-bottom: 35px;

  @media (max-width: $screen-xs-max) { height: 90px;}

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .link {
      display: none;
      @media (min-width: $screen-sm-min) {
        display: inline-block;
      }
    }
    .button {
      transition: background-color $basicTransitionDuration $basicTransitionTiming;
      margin-right: 15px;
      margin-left: 15px;

      @media (min-width: $screen-sm-min) {
        width: 210px;
        margin: 0;
      }

      &:hover {
        background-color: $gray2Opacity;
        color: $black;
      }

      &--prev,
      &--next {
        &:before {
          font-family: 'fontello';

          @media (min-width: $screen-sm-min) { display: none; }
        }
      }

      &--prev {
        &:before { content: '\e808'; }
      }

      &--next {
        &:before { content: '\e809'; }
        margin-left: auto;
      }

      span {
        display: none;

        @media (min-width: $screen-sm-min) { display: block; }
      }
    }
  }
  &-extra {
    text-align: center;

    @media (max-width: $screen-xs-max) {
      transform: translateX(-50%);
      position: absolute;
      left: 50%;
      top: 31px;
    }

    @media (min-width: $screen-sm-min) {
      padding-top: 25px;
      position: absolute;
      top: 3px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 86px;
    }

    &--longer {
      width: 140px;
    }
  }
}

.resourceKitDownloads{
  padding: 30px 0px;

  h2 {
    margin-bottom: 30px;
    font-family: $font-family-sans-serif;
    font-size: 24px;
    font-weight: 600;
    @media (min-width: $screen-md-min){
      font-size: 26px;
    }
  }

  &-Box {
    padding: 20px 0px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
    text-align:center;
    margin-bottom: 20px;
    background: repeating-linear-gradient(
      135deg,
      $gray2,
      $gray2 1px,
      $white 1px,
      $white 6px
    );

    p {
      color: $black;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    span {
      display: block;
      color:  $gray4;
      font-size: 13px;
      font-weight: 700;
    }

    &:hover {
      background: $white;
      cursor: pointer;
      p {
        text-decoration: underline;
      }
    }

    &:before {
      content: '\e81f';
      font-family: 'fontello';
      display: block;
      font-size: 35px;
      color: $brown;
    }
  }
}

.rowTeaser {
  &-singleTeaser {
    border-top: 1px solid $gray3;
    padding-bottom: 40px;
    padding-top: 30px;

    .teaserImage {
      width: 100%;
      margin-bottom: 20px;

      @media (min-width: $screen-sm-min) {
        @include inlineBlock(top);
        padding-left: 30px;
        width: 280px;
      }

      img { width: 100%; }
    }

    .teaserContent {
      @media (min-width: $screen-sm-min) {
        @include inlineBlock(top);
        width: calc(100% - 280px);
      }

      &-link {
        span {
          font-family: $font-family-sans-serif;
          margin-right: 3px;
          font-weight: 500;
          font-size: 14px;
          color: $gray4;
        }

        a { font-size: 14px; }
      }

      &-text {
        margin-bottom: 20px;
        margin-top: 20px;
      }

      &-type {
        span {
          &:first-of-type {
            font-family: $font-family-sans-serif;
            margin-right: 3px;
            font-weight: 500;
            font-size: 14px;
            color: $gray4;
          }

          &:last-of-type {
            font-family: $font-family-sans-serif;
            font-weight: 600;
            font-size: 14px;
            color: $black;
          }
        }
      }
    }
  }
}

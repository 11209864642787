.imagesList {
  $that: '.imagesList';
  &-imageWrapper {
    margin-bottom: 20px;

    img {
      transition: all $basicTransitionDuration $basicTransitionTiming;
      backface-visibility: hidden;
      width: 100%;
    }
  }

  &--withZoom {
    #{$that} {
      &-imageWrapper {
        overflow: hidden;

        &:hover {
          img { transform: scale(1.1); }
        }
      }
    }
  }
}

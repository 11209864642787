.sliderButton {
  display: inline-block;
  position: absolute;
  width: 30px;
  height: 60px;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  z-index: 1;
  &:before {
    display: block;
    height: 27px;
    width: 13px;
    font-family: 'fontello';
    font-size: 27px;
    position: absolute;
    top: 0;
    bottom: 0;
    line-height: 27px;
    margin: auto;
  }
  @media (min-width: $screen-md-min) {
    width: 33px;
    height: 92px;
  }
  &-left {
    left: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    &:before {
      left: 0px;
      content: '\e808';
      @media (min-width: $screen-sm-min) {
        left: 3px;
      }
    }
  }
  &-right {
    right: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    &:before {
      left: 4px;
      content: '\e809';
    }
  }
  &--dark {
    background-color: $navyBlue;
    &:before {
      color: $white;
    }
    &:hover {
      background-color: $darkBlue;
    }
  }
  &--white {
    background-color: $white;
    &:before {
      color: $navyBlue;
    }
    &:hover {
      background-color: $darkBlue;
    }
  }
}

@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?17942012');
  src: url('../font/fontello.eot?17942012#iefix') format('embedded-opentype'), url('../font/fontello.woff?17942012') format('woff'), url('../font/fontello.ttf?17942012') format('truetype'), url('../font/fontello.svg?17942012#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icons {
  &:before {
    font-family: 'fontello';
  }
  &:after {
    font-family: 'fontello';
  }
  &-circle {
    cursor: pointer;
    &:after {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: $black;
      border-radius: 100%;
      width: 36px;
      height: 36px;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
      background-color: #fff;
    }
    &:hover {
      &:before {
        opacity: 0.8;
      }
    }
  }
  &-doubleArrow {
    &:after {
      content: '\e805';
    }
  }
  &-print {
    display: inline-block;
    cursor: pointer;

    @media (max-width: $screen-xs-max) { margin-bottom: 15px; }

    &--gray:after {
      content: '\e801';
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: $black;
      border-radius: 100%;
      width: 36px;
      height: 36px;
      background-color: #fff;
      font-weight: 400;
      border: 1px solid $gray3;
    }

    &:after {
      content: '\e801';
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: $black;
      border-radius: 100%;
      width: 36px;
      height: 36px;
      background-color: #fff;
      font-weight: 400;
    }
    &:hover {
      &:before {
        opacity: 0.8;
      }
    }
  }
  &-chevron {
    &:after {
      display: inline-block;
      content: '\e805';
      text-align: center;
      line-height: 38px;
      color: $black;
      border-radius: 100%;
      width: 36px;
      height: 36px;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
      background-color: #fff;
      font-size: 13px;
    }
  }
}

.efc-icon-plus:before {
  content: '\e800';
  font-weight: 400;
}

.efc-icon-print:before {
  content: '\e801';
  font-weight: 400;
}

.efc-icon-search:before {
  content: '\e802';
  font-weight: 400;
}

.efc-icon-share:before {
  content: '\e803';
  font-weight: 400;
}

.efc-icon-smartphone:before {
  content: '\e804';
  font-weight: 400;
}

.efc-icon-arrow-double-down:before {
  content: '\e805';
  font-weight: 400;
}

.efc-icon-arrow-down:before {
  content: '\e806';
  font-weight: 400;
}

.efc-icon-arrow-dropdown:before {
  content: '\e807';
  font-weight: 400;
}

.efc-icon-arrow-left:before {
  content: '\e808';
  font-weight: 400;
}

.efc-icon-arrow-right:before {
  content: '\e809';
  font-weight: 400;
}

.efc-icon-donate:before {
  content: '\e80a';
  font-weight: 400;
}

.efc-icon-fax:before {
  content: '\e80b';
  font-weight: 400;
}

.efc-icon-get-involved:before {
  content: '\e80c';
  font-weight: 400;
}

.efc-icon-handshake:before {
  content: '\e80d';
  font-weight: 400;
}

.efc-icon-mail:before {
  content: '\e80e';
  font-weight: 400;
}

.efc-icon-mail-circled:before {
  content: '\e80f';
  font-weight: 400;
}

.efc-icon-phone:before {
  content: '\e810';
  font-weight: 400;
}

.efc-icon-play:before {
  content: '\e811';
  font-weight: 400;
}

.efc-icon-play-button:before {
  content: '\e812';
  font-weight: 400;
}

.efc-icon-google-plus:before {
  content: '\e813';
  font-weight: 400;
}

.efc-icon-instagram:before {
  content: '\e814';
  font-weight: 400;
  color: #405de6;
}

.efc-icon-twitter:before {
  content: '\e815';
  font-weight: 400;
  color: #00a6d3;
}

.efc-icon-youtube:before {
  content: '\e816';
  font-weight: 400;
  color: #c4302b;
}

.efc-icon-facebook:before {
  content: '\e817';
  font-weight: 400;
  color: #5a7da1;
}
/* Hands */
.efc-icon-care-for-the-vulnerable:before {
  content: '\e818';
  font-weight: 400;
}
/* Cross */
.efc-icon-church-and-mission:before {
  content: '\e819';
  font-weight: 400;
}
/* Persons */
.efc-icon-family-and-community:before {
  content: '\e81a';
  font-weight: 400;
}
/* Bird */
.efc-icon-religious-freedom:before {
  content: '\e81b';
  font-weight: 400;
}
/* Heart */
.efc-icon-sanctity-of-life:before {
  content: '\e81c';
  font-weight: 400;
}

.efc-icon-twitter-1:before {
  content: '\f099';
  font-weight: 400;
}

.efc-icon-facebook-1:before {
  content: '\f09a';
  font-weight: 400;
}

.efc-icon-gplus:before {
  content: '\f0d5';
  font-weight: 400;
}

.efc-icon-pinterest:before {
  content: '\f231';
  font-weight: 400;
}

.efc-icon-location:before {
  content: '\e81d';
  font-weight: 400;
}

.efc-icon-x:before {
  content: '\e81e';
  font-weight: 400;
}

.efc-icon-speaker:before {
  content: '\e820';
  font-weight: 400;
}

.efc-icon-download:before {
  content: '\e81f';
  font-weight: 400;
}

.efc-icon-rss:before {
  content: '\e821';
  font-weight: 400;
}

.efc-icon-get-informed:before {
  content: '\e822';
}

.efc-icon-support-efc:before {
  content: '\e823';
}

.efc-icon-what-can-you-do:before {
  content: '\e824';
}

.efc-icon-donate-fill:before {
  content: '\e825';
}

.efc-icon-take-action:before {
  content: '\e826';
}

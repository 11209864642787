.ornamentBox {
  $that: '.ornamentBox';
  position: relative;
  margin-top: 25px;

  display: none;

  @media (min-width: $screen-md-min) {
    display: block;
  }

  &-title {
    font-family: $font-family-serif;
    transform: translateX(-50%);
    margin-bottom: -10px;
    white-space: nowrap;
    margin-right: auto;
    position: absolute;
    margin-left: auto;
    font-weight: 400;
    font-size: 25px;
    color: $black;
    z-index: 1;
    left: 50%;
    top: 40px;
  }

  &-ornament {
    /* Element variables */
    $ornamentHeight: 110px;

    border-radius: $ornamentHeight/2;
    background-color: $white;
    height: $ornamentHeight;
    width: $ornamentHeight;
    margin-right: auto;
    position: relative;
    margin-left: auto;
    border: 5px solid;

    &--brown { border-color: $brown; }

    .ornamentBackground {
      height: $ornamentHeight - 25px;
      background-color: $white;
      width: $ornamentHeight;
      position: absolute;
      left: -5px;
      top: -5px;
    }

    img {
      height: $ornamentHeight - 20px;
      width: $ornamentHeight - 20px;
      position: absolute;
      left: 5px;
      top: 5px;
    }
  }

  &-content {
    margin-top: -25px;
    font-family: $font-family-sans-serif;
    border-top: 5px solid $brown;
    padding: 45px 20px 0 20px;
    background-color: $gray1;
    line-height: 1.5em;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: $black;
    h6 {
      text-align: center;
      font-size: 24px;
      margin-bottom: 15px;
    }
    p {
      text-align: center;
      font-size: 14px;
      span {
        display: inline-block;
        font-weight: 500;
      }
    }
  }

  .helpers-textCenter {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }

  &-corners {
    background-color: $gray1;
    margin-top: -20px;
    height: 40px;
    width: 100%;

    &--both {
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &--left { border-bottom-left-radius: 20px; }
    &--right { border-bottom-right-radius: 20px; }
  }

  &-extraContent {
    .roundedBar {
      transition: all $basicTransitionDuration $basicTransitionTiming;
      font-family: $font-family-sans-serif;
      border-bottom-left-radius: 25px;
      border-top-left-radius: 25px;
      text-transform: uppercase;
      background-color: $gray1;
      font-weight: 700;
      margin-top: 1px;
      font-size: 13px;
      display: flex;
      align-items: center;
      color: $black;
      height: 50px;

      .themeDecoration {
        background-color: $white;
        margin-right: 10px;
        margin-left: 10px;
        color: $brown;
        font-size: 36px;

        &-getInformed {
          &:before {
            content: '\e822';
          }
        }

        &-supportEfc {
          &:before {
            content: '\e823';
          }
        }

        &-whatCanYouDo {
          &:before {
            content: '\e824';
          }
        }

        &-donate {
          &:before {
            content: '\e825';
          }
        }

        &-takeAction {
          &:before {
            content: '\e826';
          }
        }
      }

      &:hover { background-color: $gray2; }
    }
  }
  &--contact {
    margin-bottom: 50px;
    #{$that}-ornament img {
      height: 66px;
      width: 70px;
      position: absolute;
      left: 14px;
      top: 18px;
    }
    #{$that}-content {
      padding-bottom: 20px;
    }
  }
}

.ourTeamBox {
  img {
    display: block;
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
    margin-bottom: 10px;
  }

  &-description{

    padding: 10px;
    text-align: center;

    h2 {
      font-size: 24px;
      @media (min-width: $screen-sm-min) {
        font-size: 26px;
      }
    }

    p {
      height: auto;
      @media (min-width: $screen-sm-min) {
        height: 80px;
      }
    }

    a {
      margin: auto;
    }
  }
}

.tree {
  &-heading {
    display: block;
    position: relative;
    font-family: $font-family-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    padding-left: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    &:before {
      display: inline-block;
      position: absolute;
      left: 0;
      top: 7px;
      font-family: 'fontello';
      content: '\e807';
      color: $brown;
      font-size: 7px;
      transition: transform 0.2s;
    }
    &.toggler--target-in {
      &:before {
        transform: rotate(180deg);
      }
    }
  }
  &-list {
    display: none;
    @include clearList();
    li {
      padding-left: 20px;
      a {
        font-size: 13px;
        font-weight: 500;
        color: $darkgray;
        &:hover {
          color: $black;
        }
      }
    }
  }
}

.helpers {
  &-flex {
    display: flex;
    &--spaceBetween {
      justify-content: space-between;
    }
  }
  &-relative {
    position: relative;
  }
  &-textCenter {
    text-align: center;
  }

  &-noPadding--right {
    padding-right: 0px;
  }

  &-noPadding--left {
    padding-left: 0px;
  }

  &--removedParentMarginLeft {
    @media (min-width: $screen-md-min) {
      margin-left: -15px;
    }
  }

  &-addBootstrapPading--left {
    padding-left: 15px
  }

  &-addBootstrapPading--right {
    padding-right: 15px
  }

  &-addBootstrapDoublePading--left {
    padding-left: 30px
  }

  &-addBootstrapDoublePading--right {
    padding-right: 30px
  }

  &--right {
    text-align: right;
  }

  &--left {
    text-align: left;
  }
  &-smallMargin {
    &--top {
      margin-top: 20px !important;
    }
  }
}

.galleryMagnificPopup{
  background-color: $white;
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 10px 15px;
  margin-top: -4px;
  margin-right: -36px;
  position: absolute;

  h3 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 5px;
  }

  &-date {
    color: $darkgray;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  &-description {
    color: $black;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0px;
  }

}

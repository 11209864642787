/* #fff */
$white: rgba(255, 255, 255, 1);
$whiteOpacity: rgba(255, 255, 255, 0.65);

/* #f5f5f5 */
$whitesmoke: rgba(245, 245, 245, 1);
$whitesmokeOpacity: rgba(245, 245, 245, 0.65);

/* #f5f5f5 */
$gray1: rgba(245, 245, 245, 1);
$gray1Opacity: rgba(245, 245, 245, 0.65);

/* #ebebeb */
$gray2: rgba(235, 235, 235, 1);
$gray2Opacity: rgba(235, 235, 235, 0.65);

/* #dedede */
$gray3: rgba(222, 222, 222, 1);
$gray3Opacity: rgba(222, 222, 222, 0.65);

/* #919191 */
$gray4: rgba(145, 145, 145, 1);
$gray4Opacity: rgba(145, 145, 145, 0.65);

/* #000 */
$black: rgba(0, 0, 0, 1);
$blackOpacity: rgba(0, 0, 0, 1);

/* #5a7da1 */
$navyBlue: rgba(90, 125, 161, 1);
$navyBlueOpacity: rgba(90, 125, 161, 0.65);

/* #528fcc */
$blue: rgba(82, 143, 204, 1);
$blueOpacity: rgba(82, 143, 204, 0.65);

/* #3f6488 */
$darkBlue: rgba(63, 100, 136, 1);
$darkBlueOpacity: rgba(63, 100, 136, 0.65);

/* #b1a36f */
$brown: rgba(177, 163, 111, 1);
$brownOpacity: rgba(177, 163, 111, 0.65);

/* #e62600 */
$red: rgba(230, 38, 0, 1);
$redOpacity: rgba(230, 38, 0, 0.65);

/* #e6dcb8 */
$beige: rgba(230, 220, 184, 1);
$beigeOpacity: rgba(230, 220, 184, 0.65);

/* #595959 */
$darkgray: rgba(89, 89, 89, 1);
$darkgrayOpacity: rgba(89, 89, 89, 0.65);


/* #006699 */
$themeBlue: rgba(0, 102, 153, 1);
$themeBlueOpacity: rgba(0, 102, 153, 0.65);

/* #cc0033 */
$themeRed: rgba(204, 0, 51, 1);
$themeRedOpacity: rgba(204, 0, 51, 0.65);

/* #ffcb00 */
$themeYellow: rgba(255, 203, 0, 1);
$themeYellowOpacity: rgba(255, 203, 0, 0.65);

/* #009943 */
$themeGreen: rgba(0, 153, 67, 1);
$themeGreenOpacity: rgba(0, 153, 67, 0.65);

/* #660099 */
$themePurple: rgba(102, 0, 153, 1);
$themePurpleOpacity: rgba(102, 0, 153, 0.65);

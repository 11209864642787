.universalSlider {
  display: none;

  &.slick-initialized { display: block; }

  &.slick-dotted { margin-bottom: 50px; }

  &-slideContent {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    height: 320px;

    .slideDescription {
      background-color: rgba(0, 0, 0, 0.3);
      position: absolute;
      height: 160px;
      color: $white;
      width: 100%;
      bottom: 0;
      left: 0;

      @media (min-width: $screen-sm-min) {
        padding-left: 140px;
        height: 140px;
      }

      @media (min-width: $screen-lg-min) { padding-left: 150px; }

      &-icon {
        transform: translateX(-50%);
        font-family: 'fontello';
        position: absolute;
        font-size: 36px;
        left: 50%;
        top: 0;

        @media (min-width: $screen-sm-min) {
          transform: translateY(40%);
          font-size: 60px;
          bottom: 50%;
          left: 64px;
        }

        @media (min-width: $screen-lg-min) { font-size: 64px; }

        &--hands {
          &:before {
            font-weight: 400;
            content: '\e818';
          }
        }

        &--cross {
          &:before {
            font-weight: 400;
            content: '\e819';
          }
        }

        &--persons {
          &:before {
            font-weight: 400;
            content: '\e81a';
          }
        }

        &--bird {
          &:before {
            font-weight: 400;
            content: '\e81b';
          }
        }

        &--heart {
          &:before {
            font-weight: 400;
            content: '\e81c';
          }
        }
      }

      &-title {
        font-family: $font-family-sans-serif;
        text-align: center;
        font-weight: 600;
        margin-top: 45px;
        font-size: 17px;
        color: $white;

        @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) { margin-top: 30px; }

        @media (min-width: $screen-sm-min) {
          text-align: left;
          font-size: 20px;
        }

        @media (min-width: $screen-lg-min) {
          margin-top: 25px;
          font-size: 22px;
        }
      }

      &-text {
        font-family: $font-family-serif;
        line-height: 32px;
        font-size: 32px;
        color: $white;


        @media (max-width: $screen-xs-max) {
          transform: translateY(calc(50% - 30px));
          bottom: calc(50% - 60px);
          padding-right: 20px;
          padding-left: 20px;
          text-align: center;
          position: absolute;
          width: 100%;
          left: 0;
        }

        @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
          line-height: 50px;
          font-size: 50px;
        }

        @media (min-width: $screen-lg-min) {
          line-height: 64px;
          font-size: 64px;
        }
      }
    }
  }

  &:after { bottom: -52px; }

  .sliderButton {
    transition: background-color $basicTransitionDuration $basicTransitionTiming;
    background-color: $navyBlueOpacity;

    &:before {
      font-weight: 300;
      color: $white;
    }

    &:hover { background-color: $navyBlue; }

    &:focus { outline: none; }

    &-right { right: -1px; }
  }

  .slick-dots {
    bottom: -30px;

    li { margin: 0; }
  }
}

.relatedTopics {
  margin-bottom: 30px;
  &-heading {
    font-size: 24px;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    margin-bottom: 23px;
    @media (min-width: $screen-sm-min) {
      font-style: 28px;
    }
  }
  &-list {
    @include clearList();
    li {
      display: flex;
      padding-top: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid $gray2;
      &:first-of-type {
        border-top: 1px solid $gray2;
      }
    }
  }
  &-icons {
    flex: 0 0 80px;
    text-align: right;
    a {
      display: inline-block;
      margin-right: 3px;
    }
  }
  &-title {
    padding-left: 15px;
    color: $black;
    font-size: 16px;
    font-weight: 400;
    &:hover {
      color: $blue;
    }
  }
}

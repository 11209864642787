.specificContentSearch {
  position: relative;
  margin-top: 40px;
  margin-bottom: 35px;

  &.noMargin { margin-top: 0; }

  &-header {
    font-family: $font-family-serif;
    padding-bottom: 20px;
    position: relative;
    padding-top: 15px;
    font-weight: 400;
    font-size: 28px;
    color: $black;

    &.radialShadow {
      &-image {
        &--top {
          &:before {
            width: 100%;
            left: 0;
          }
        }
      }
    }
  }

  &-button {
    transition: all $basicTransitionDuration $basicTransitionTiming;
    border-bottom: 2px solid $brown;
    text-transform: uppercase;
    padding-bottom: 3px;
    position: absolute;
    cursor: pointer;
    top: -50px;
    right: 0;

    &:hover,
    &.decorated {
      border-bottom-color: $navyBlue;

      &:after {
        bottom: -11px;
        color: $navyBlue;
      }
    }

    &:after {
      transition: all $basicTransitionDuration $basicTransitionTiming;
      transform: translateX(-50%);
      font-family: 'fontello';
      position: absolute;
      content: '\e807';
      font-size: 10px;
      color: $brown;
      bottom: -7px;
      left: 50%;
    }

    span {
      font-family: $font-family-sans-serif;
      background-color: $white;
      padding-bottom: 6px;
      position: relative;
      font-weight: 700;
      font-size: 13px;
      color: $black;
      z-index: 1;
    }
  }

  &.noButton {
    .specificContentSearch {
      &-button { display: none; }

      &-content { display: block; }
    }
  }

  &-content {
    box-shadow: $BoxShadowCenter;
    display: none;

    .contentTypeSelector {
      border-bottom: 1px solid $gray2;

      .decoratedSelect {
        width: calc(100% - 30px);

        @media (min-width: $screen-sm-min) { width: calc(100% - 305px); }
      }
    }

    .bodySearch { box-shadow: none; }
  }
}

.themeBox {
  display: inline-block;
  position: absolute;
  right: -21px;
  &--center {
    top: 50%;
    transform: translateY(-50%);
  }
  &--top {
    top: 8px;
  }
  &-decoration {

  }
  &-element {
    display: flex;
    align-items: center;
    position: relative;
  }
  &-link {
    display: inline-block;
    height: 36px;
    position: absolute;
    right: 0;
    top: 0;
    white-space: nowrap;
    background-color: red;
    z-index: -1;
    border-radius: 100px;
    line-height: 36px;
    overflow: hidden;
    width: 36px;
    color: $white;
    font-family: $font-family-sans-serif;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    &:hover {
      color: $white;
    }
    span {
      padding-right: 46px;
      padding-left: 20px;
    }
  }
}

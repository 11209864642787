@mixin inlineBlock($vertical-align: baseline) {
  vertical-align: $vertical-align;
  display: inline-block;
  margin-right: -4px;
}

@mixin backgroundCentered() {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

// !!! Deprecated
// radial-shadow mixin rely on positioned parent!
@mixin radial-shadow($size, $position: top) {
  @if $position == top
  {
    &:after {
      position: absolute;
      content: '';
      width: $size;
      height: 20px;
      border-radius: 50%;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      box-shadow: 0 0 20px $gray4;
    }
  } @else {
    &:before {
      position: absolute;
      content: '';
      width: $size;
      height: 20px;
      border-radius: 50%;
      z-index: -1;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      box-shadow: 0 0 20px $gray4;
    }
  }
}

@mixin clearList() {
  list-style: none;
  margin: 0;
  padding: 0;
}

.searchInfo {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 25px;

  &-query {
    display: inline-block;
    border-radius: 4px;
    background-color:  #ebebeb;
    padding: 2px 8px;
    font-weight: 600;
  }
  &-type {
    font-weight: 600;
  }
  &-again {
    cursor: pointer;
  }
}

.header {
  > .donateButton {
    display: none;

    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
      padding: 11px 25px 0 50px;
      position: absolute;
      display: block;
      right: 110px;
      z-index: 2;
      top: 21px;

      &:before {
        transform: translateY(-50%);
        font-family: 'fontello';
        position: absolute;
        content: '\e80a';
        font-size: 20px;
        left: 20px;
        top: 50%;
      }
    }
  }

  .categories {
    &-extraContent {
      @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) { border-top: 10px solid $gray1; }

      @media (min-width: $screen-lg-min) {
        position: absolute;
        right: 15px;
        top: 15px;
      }

      .searchBox {
        @media (max-width: $screen-md-max) {
          position: absolute;
          left: 15px;
          top: 5px;
        }

        @media (min-width: $screen-lg-min) {
          @include inlineBlock;
          border-radius: 18px;
          position: relative;
          height: 36px;

          input[type="text"],
          input[type="submit"] { height: 36px; }
        }
      }

      a {
        font-family: $font-family-sans-serif;
        text-transform: uppercase;
        color: $gray4;

        @media (max-width: $screen-md-max) {
          padding: 12px 0 11px 20px;
          font-size: 13px;
          display: block;

          &:not(:first-of-type) { border-bottom: 1px solid $gray1; }
        }

        @media (min-width: $screen-lg-min) {
          transition: color $basicTransitionDuration $basicTransitionTiming,
                      background-color $basicTransitionDuration $basicTransitionTiming ;
          @include inlineBlock;
          margin-left: 25px;
          font-weight: 700;
          margin-right: 0;
          font-size: 12px;

          &:hover { color: $black; }
        }

        &.donateButton {
          position: relative;
          color: $white;

          @media (max-width: $screen-xs-max) {
            padding-left: 50px;
            border-radius: 0;
            height: 41px;

            &:hover {
              background-color: $navyBlue;
              color: $white;
            }
          }

          @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) { display: none; }

          @media (min-width: $screen-lg-min) {
            padding: 10px 20px 0 50px;
            @include inlineBlock;
            border-radius: 18px;
            height: 36px;

            &:hover { color: $black; }
          }

          &:before {
            transform: translateY(-50%);
            font-family: 'fontello';
            position: absolute;
            content: '\e80a';
            font-size: 20px;
            left: 20px;
            top: 50%;
          }
        }
      }
    }
  }
}

.accordion {
  h4 {
    display: none;
    @media (min-width: $screen-md-min) {
      display: block;
      font-family: $font-family-sans-serif;
      color: $black;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
  }
  button {
    @media (min-width: $screen-md-min) {
      display: none;
    }
  }
  ul {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    @media (min-width: $screen-md-min) {
      display: block !important;
    }
  }
  li {
    text-align: center;
    @media (min-width: $screen-md-min) {
      text-align: left;
    }
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
    color:  #a6a6a6;
    font-size: 13px;
    font-weight: 700;
    line-height: 26px;
    text-transform: uppercase;
    &:hover {
      color: $black;
    }
  }
}

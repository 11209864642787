.moreVideos {
  padding: 20px 0px;

  &-header {
    margin-bottom: 20px;
  }

  h3 {
    font-size: 26px;
    margin-bottom: 20px;
    @media (min-width: $screen-sm-min) {
      font-size: 30px;
      display: inline-block;
      margin-bottom: 0px;
    }
  }
  .decoratedSelect {
    @media (min-width: $screen-sm-min) {
      float: right;
    }
  }

  &-videosList {
    border-top: 1px solid $gray3;
    // padding-top: 10px;
    margin-bottom: 30px;
    text-align: left;
  }
}

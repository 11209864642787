.topicHighlights {
  &-header { margin-bottom: 10px; }

  hr { margin: 0; }

  &-links {
    margin-bottom: 40px;
    margin-right: -1px;
    text-align: center;
    margin-left: -4px;
    padding-top: 10px;

    @media (min-width: $screen-sm-min) {
      margin-right: -8px;
      margin-left: -11px;
    }

    @media (min-width: $screen-md-min) {
      margin-right: 0;
      margin-left: 0;
    }

    > div {
      @include inlineBlock(top);
      margin-bottom: 8px;
      width: 50%;

      @media (min-width: $screen-sm-min) { width: 25%; }

      @media (min-width: $screen-md-min) {
        display: block;
        width: 100%;
      }

      > a {
        margin-right: 3px;
        margin-left: 3px;
        overflow: hidden;
        display: block;

        &:hover {
          > img { transform: scale(1.1); }
        }

        @media (min-width: $screen-sm-min) {
          margin-bottom: 14px;
          margin-right: 10px;
          margin-left: 10px;
        }

        @media (min-width: $screen-md-min) {
          margin-bottom: 10px;
          margin-right: 0;
          margin-left: 0;
        }

        > img {
          transition: all $basicTransitionDuration $basicTransitionTiming;
          backface-visibility: hidden;
          width: 100%;
        }
      }
    }
  }
}

.articleTeaser {
  $that: ".articleTeaser";
  border-bottom: 1px solid $gray3;
  margin-bottom: 26px;

  &--withButtons {
    #{$that} {
      &-image {
        @media (min-width: $screen-sm-min) { height: 160px; }
      }
    }
  }

  &-image {
    display: inline-block;
    position: relative;
    width: calc(100% - 18px);
    height: 145px;
    border: 0;
    border-right-style: solid;
    border-right-width: 5px;
    margin-bottom: 9px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media (min-width: $screen-sm-min) {
      width: 241px;
      height: 127px;
      margin-right: 27px;
    }
  }
  &-description {
    @media (min-width: $screen-sm-min) {
      display: inline-block;
      width: calc(100% - 290px);
      vertical-align: top;
    }
    &--resourceKits {
      @media (min-width: $screen-sm-min) {
        float: right;
      }
      @media (min-width: $screen-md-min) {
        float: none;
      }
      @media (min-width: $screen-lg-min) {
        float: right;
      }
    }
  }
  &-link {
    display: block;
    width: 100%;
    height: 100%;
  }
  h4 {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 11px;
    @media (min-width: $screen-sm-min) {
      font-size: 24px;
      line-height: 28px;
    }
  }
  time {
    display: inline-block;
    margin-bottom: 12px;
    margin-right: 20px;
  }
  .topicDecorator {
    margin-bottom: 18px;
  }
  .link {
    margin-right: 200px;
  }

  &--overflow {
    overflow: hidden;
  }
}

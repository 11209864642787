.header {
  background-color: $white;
  position: fixed;
  height: 59px;
  z-index: 10;
  width: 100%;

  @media (min-width: $screen-sm-min) { height: 79px; }

  @media (min-width: $screen-lg-min) {
    @include container-fixed;
    width: $container-lg;
    position: relative;
    height: 110px;

    &:after { z-index: -1; }
  }

  &-background {
    background-color: $white;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;

    @media (min-width: $screen-lg-min) { display: none; }
  }

  .trigram {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;

    @media (min-width: $screen-lg-min) { display: none; }
  }

  .categories {
    @media (max-width: $screen-md-max) {
      transition: right $basicTransitionDuration $basicTransitionTiming;
      border-top: 1px solid $gray1;
      background-color: $white;
      padding-bottom: 100px;
      position: absolute;
      margin-top: 59px;
      overflow-y: auto;
      position: fixed;
      right: -320px;
      height: 100%;
      width: 320px;
      top: 0;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
      padding-bottom: 120px;
      margin-top: 79px;
    }

    @media (min-width: $screen-lg-min) {
      @include inlineBlock(top);
      width: calc(100% - 320px);
    }

    &-content {
      list-style-type: none;
      padding: 60px 0 0 0;
      margin: 0;
      right: 0;

      @media (min-width: $screen-lg-min) {
        padding: 55px 0 0 0;
        text-align: right;
      }

      .level0 {
        border-top: 1px solid $gray1;

        &.offset {
          .level1 {
            position: absolute;
            right: 0;
          }
        }

        @media (max-width: $screen-md-max) {
          background-color: $white;
          width: 100%;

          &.activeLevel {
            border-bottom: 1px solid $gray1;
            position: absolute;
            z-index: 1;
            top: 60px;
            left: 0;

            .level0-trigger {
              background-color: $gray2;
              padding-left: 50px;

              &:after { content: none; }

              &:before {
                transform: translateY(-50%);
                font-family: 'fontello';
                padding-right: 10px;
                position: absolute;
                font-weight: 700;
                content: '\e808';
                font-size: 11px;
                color: #5a7da1;
                left: 20px;
                top: 50%;
              }
            }

            .level1 { display: block; }
          }

          &.inactiveLevel {
            //position: absolute;
            //z-index: 0;
            top: 60px;
            left: 0;
          }
        }

        @media (min-width: $screen-lg-min) {
          @include inlineBlock;
          border-top: none;
          > a {
            &:before {
              transition: all $basicTransitionDuration $basicTransitionTiming;
              border-right: 1px solid transparent;
              border-left: 1px solid transparent;
              position: absolute;
              height: 25px;
              content: "";
              width: 100%;
              bottom: 0;
              left: 0;
            }

            &:after {
              transition: all $basicTransitionDuration $basicTransitionTiming;
              width: calc(100% - 40px);
              background-color: transparent;
              position: absolute;
              top: 45px;
              height: 3px;
              content: "";
              left: 20px;
            }
          }
          &.activeLevel {
            a {
              &:after {
                background-color: $gray2;
              }
            }
          }
          &:hover {
            a {
              &:before {
                border-right-color: $gray2;
                border-left-color: $gray2;
              }

              &:after { background-color: $gray2; }
            }

            .level1 { display: block; }
          }
        }

        &-trigger {
          font-family: $font-family-serif;
          padding: 9px 40px 8px 20px;
          position: relative;
          font-weight: 400;
          font-size: 17px;
          display: block;
          color: $black;

          @media (min-width: $screen-lg-min) {
            padding: 15px 20px;
            font-size: 18px;
          }

          &:after {
            transform: translateY(-50%);
            font-family: 'fontello';
            padding-right: 10px;
            position: absolute;
            font-weight: 700;
            content: '\e809';
            color: $navyBlue;
            font-size: 11px;
            right: 0;
            top: 50%;

            @media (min-width: $screen-lg-min) { content: none; }
          }
        }

        .level1 {
          background-color: #fff;
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);

          @media (max-width: $screen-md-max) {
            box-shadow: none;
            display: none;
          }

          ul {
            padding-right: 20px;
            padding-left: 20px;

            li {
              position: relative;

              .themes {
                position: absolute;
                right: 0;
                top: 6px;

                .themeDecoration {
                  font-size: 22px;
                  height: 22px;
                  width: 22px;

                  &:before { margin-left: 0; }
                }
              }
            }
          }

          @media (min-width: $screen-lg-min) {
            position: absolute;
            text-align: left;
            display: none;
            padding-top: 20px;
            top: 110px;

            ul {
              padding-right: 30px;
              padding-left: 30px;

              &:not(:last-of-type) { border-right: 1px solid $gray2; }
            }
          }

          &.extended {
            @media (min-width: $screen-lg-min) {
              width: calc(100% - 90px);
              left: 45px;

              ul {
                width: 33.333333%;
                @include inlineBlock(top);
              }
            }
          }

          &.normal {
            @media (min-width: $screen-lg-min) {
              padding-bottom: 15px;

              ul {
                @include inlineBlock(top);
                width: 300px;
              }
            }
          }

          > ul {
            list-style-type: none;

            > li {
              &:not(:first-of-type) { border-top: 1px solid $gray2; }
              &:last-of-type {
                border-bottom: 1px solid $gray2;
              }

              > a {
                transition: color $basicTransitionDuration $basicTransitionTiming;
                font-family: $font-family-sans-serif;
                padding-bottom: 10px;
                font-weight: 600;
                padding-top: 9px;
                font-size: 13px;
                display: block;
                color: $black;

                &:hover { color: $blue; }
              }
            }
          }

          .themesShortcuts {
            $that: '.themesShortcuts';
            display: none;

            @media (min-width: $screen-lg-min) {
              display: block;

              > a {
                transition: background-color $basicTransitionDuration $basicTransitionTiming;
                font-family: $font-family-sans-serif;
                text-transform: uppercase;
                background-color: $gray1;
                @include inlineBlock;
                vertical-align: bottom;
                text-align: center;
                font-weight: 700;
                margin-top: 20px;
                font-size: 13px;
                padding: 20px;
                color: $black;
                width: 20%;

                &:hover { background-color: $white; }

                #{$that}-wrapper {
                  transform: translateY(-50%);
                  position: relative;
                  top: 50%;

                  .themeDecoration { margin-bottom: 10px; }
                  }
                }

                &:hover { background-color: $white; }
              }
            }
          }
        }
      }
    }
  // }
}

.headerWrapper { position: relative; }

.fixedHeader {
  &.header {
    margin-left: -$container-lg/2;
    background-color: $white;
    width: $container-lg;
    position: fixed;
    height: 60px;
    z-index: 2;
    top: -60px;
    left: 50%;

    .categories {
      &-content {
        background-color: #fff;
        padding: 4px 75px 0 0;

        .level0 {
          &-trigger { padding: 15px; }

          .level1 { top: 58px; }
        }
      }

      &-extraContent {
        background-color: $white;
        position: absolute;
        height: 59px;
        width: 115px;
        right: 0;
        top: 0;

        .searchBoxHeader {
          transition: bottom $basicTransitionDuration $basicTransitionTiming;
          position: absolute;
          bottom: 20px;
          z-index: -1;
          right: 20px;

          &:before {
            background-color: $white;
            border-radius: 25px;
            position: absolute;
            display: block;
            height: 76px;
            width: 310px;
            right: -10px;
            content: "";
            z-index: -1;
            top: -30px;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
          }

          &.show { bottom: -46px; }
        }

        > a {
          &:not(:first-of-type) { display: none; }
        }

        .donateButton {
          position: absolute;
          right: 60px;
          top: 12px;
        }

        .searchBoxHeaderTrigger {
          &:after {
            transition: all $basicTransitionDuration $basicTransitionTiming;
            font-family: 'fontello';
            position: absolute;
            content: '\e802';
            font-size: 18px;
            cursor: pointer;
            color: $gray4;
            right: 15px;
            z-index: 0;
            top: 17px;

            visibility: visible;
            opacity: 1;
          }

          &:before {
            transition: all $basicTransitionDuration $basicTransitionTiming;
            font-family: 'fontello';
            position: absolute;
            content: '\e81e';
            font-size: 18px;
            cursor: pointer;
            color: $gray4;
            right: 15px;
            z-index: 0;
            top: 17px;

            visibility: hidden;
            opacity: 0;
          }

          &:hover {
            &:after,
            &:before { color: $black; }
          }

          &.clicked {
            &:after {
              transform: rotate(360deg);
              visibility: hidden;
              opacity: 0;
            }

            &:before {
              transform: rotate(360deg);
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

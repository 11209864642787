.newsletterBox {
  position: relative;
  background-color: $gray1;
  padding: 35px 15px;
  padding-top: 25px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  text-align: center;

  &:before {
    display: inline-block;
    text-align: center;
    line-height: 34px;
    font-family: 'fontello';
    background-color: $brown;
    width: 36px;
    height: 36px;
    content: '\e80e';
    color: $white;
    border-radius: 100%;
    position: absolute;
    top: -18px;
    left:0;
    right: 0;
    margin: auto;
    font-size: 21px;
  }
  &-header {
    font-size: 30px;
    text-align: center;
    margin-bottom: 15px;
  }
  &-description {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }


  &-input {
    width: 100%;
    margin: auto;
    text-align: center;
    margin-bottom: 10px;

    @media (min-width: $screen-sm-min) { width: 75%; }

    @media (min-width: $screen-lg-min) { width: 55%; }

    input[type="text"] {
      border: 2px solid $gray3;
      border-radius: 20px;
      height: 36px;
      margin: auto;
      width: 100%;
      padding-left: 45px;
      color:  $black;
      font-size: 13px;
      background-color: transparent;
      appearance: none;
      background: url(/assets/img/email.png) no-repeat 25px 50%;
      background-color: $white;
      padding-right: 40px;

        @media (max-width: $screen-xs-max) { margin-bottom: 10px; }

      &:focus { outline: 0; }

      @media (min-width: $screen-sm-min) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: -5px;
        width: calc(99% - 100px);
      }
    }

    ::-webkit-input-placeholder {
       color: $black;
    }

    :-moz-placeholder {
       color: $black;
    }

    ::-moz-placeholder {
       color: $black;
    }

    :-ms-input-placeholder {
       color: $black;
    }

    input[type="submit"] {
      transition: background-color $basicTransitionDuration $basicTransitionTiming;
      color:  #ffffff;
      font-size: 13px;
      font-weight: 700;
      line-height: 24px;
      font-family: $font-family-sans-serif;
      appearance: none;
      background-color: $navyBlue;
      border: none;
      height: 36px;
      border-radius: 4px;
      margin-left: auto;
      margin-right: auto;
      width: 100px;

      &:hover { background-color: $navyBlueOpacity; }

      &:focus { outline: none; }
    }
  }

  &-socialIcons {
    background-color: $white;
    @include inlineBlock;
    border-radius: 25px;
    padding-right: 8px;
    padding-left: 1px;

    a { display: inline-block; }
  }

  a:before {
    font-family: 'fontello';
    font-size: 36px;
    margin-left: 7px;
  }
}

.stickyHeaderBackground {
  background-color: $white;
  position: fixed;
  display: none;
  height: 60px;
  width: 100%;
  z-index: 2;
  left: 0;
  top: 0;

  @media (min-width: $screen-md-min) { display: block; }
}

.carouselBox {
  $that: '.carouselBox';
  position: relative;
  text-align: center;
  padding-right: 15px;
  vertical-align: top;
  @media (min-width: $screen-lg-min) {
    padding-right: 0;
  }
  &-image {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 136px;
    background-size: cover;
    background-position: center;
    border:0;
    border-right: 6px;
    border-style: solid;
    margin-bottom: 6px;
    @media (min-width: $screen-sm-min) {
      width: 267px;
      height: 151px;
    }
    @media (min-width: $screen-md-min) {
      width: 315px;
      height: 166px;
    }
  }
  &-anchor {
    display: block;
    width: 100%;
    height: 100%;
  }
  h4 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 12px;
    padding-left: 10px;
    padding-right: 10px;
    @media (min-width: $screen-sm-min) {
      font-size: 26px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  p {
    padding-right: 10px;
    padding-left: 10px;

    @media (min-width: $screen-sm-min) {
      padding-right: 25px;
      padding-left: 25px;
    }
  }
  &-type {
    transition: all $basicTransitionDuration $basicTransitionTiming;
    position: absolute;
    bottom: 0;
    left: -1px;
    display: inline-block;
    background-color: #fff;
    padding-top: 8px;
    padding-right: 10px;
    padding-left: 5px;
    text-transform: uppercase;
    color: $black;
    font-size: 11px;
    font-weight: 700;
    border-top-right-radius: 3px;

    &:hover {
      background-color: $gray1;
      color: $gray4;
    }
  }
  .topicDecorator {
    margin-bottom: 13px;
  }
  // States
  &--article {
    display: inline-block;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid $gray3;
    padding-top: 30px;
    padding-bottom: 60px;
    background-color: #fff;
    transition: background-color $basicTransitionDuration $basicTransitionTiming;
    &:last-of-type {
      border-bottom: 0;
    }
    &:hover {
      background-color: $gray1;

      .carouselBox-type { background-color: $gray1; }
    }
    @media (min-width: $screen-sm-min) {
      width: 50%;
      padding-left: 30px;
      padding-right: 28px;
      margin-right: -0.25em;
      border-bottom: 0;
      border-right: 1px solid $gray3;
      &:nth-of-type(2n) {
        border-right: 0;
      }
    }
    @media (min-width: $screen-md-min) {
      width: 33.3%;
      &:nth-of-type(2n) {
        border-right: 1px solid $gray3;
      }
      &:nth-of-type(3n) {
        border-right: 0;
      }
    }
    #{$that}-image {
      width: 100%;
      height: 145px;
      @media (min-width: $screen-sm-min) {
        height: 176px;
      }
    }

    .topicDecorator { padding-left: 15px; }

    p {
      padding-right: 15px;
      padding-left: 15px;
    }

    .link {
      position: absolute;
      margin-bottom: 0;
      bottom: 30px;
      left: 15px;

      @media (min-width: $screen-sm-min) { left: 30px; }
    }

    .share {
      position: absolute;
      bottom: 30px;
      right: 15px;

      @media (min-width: $screen-sm-min) { right: 30px; }
    }
  }
  &--court {
    display: inline-block;
    text-align: left;
    border-bottom: 1px solid $gray3;
    padding-top: 30px;
    background-color: #fff;
    transition: background-color $basicTransitionDuration $basicTransitionTiming;
    &:last-of-type {
      border-bottom: 0;
    }
    &:hover {
      background-color: $gray1;

      .carouselBox-type { background-color: $gray1; }
    }
    @media (min-width: $screen-sm-min) {
      width: 50%;
      padding-left: 30px;
      padding-right: 28px;
      border-bottom: 0;
      border-right: 1px solid $gray3;
      &:nth-of-type(2n) {
        border-right: 0;
      }
    }
    h4 {
      position: relative;
      padding-left: 15px;
      &:before {
        position: absolute;
        left: 0;
        display: inline-block;
        content: '';
        height: 100%;
        width: 5px;
        background-color: $brown;
        border-radius: 25%;
      }
      @media (min-width: $screen-sm-min) {
        font-size: 28px;
      }

      &.hands {
        &:before { background-color: $themeGreen; }
      }

      &.cross {
        &:before { background-color: $themeBlue; }
      }

      &.persons {
        &:before { background-color: $themePurple; }
      }

      &.bird {
        &:before { background-color: $themeYellow; }
      }

      &.heart {
        &:before { background-color: $themeRed; }
      }
    }

    #{$that}-image {
      width: 100%;
      height: 145px;
      @media (min-width: $screen-sm-min) {
        height: 176px;
      }
    }
    p {
      padding: 0;
    }
  }
  &--webinar {
    width: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    transition: none;
    border-bottom: 0;
    padding-top: 0;
    padding-right: 0;
    margin-top: 20px;
    &:hover {
      background: none;
    }
    @media (min-width: $screen-sm-min) {
      width: calc(50% - 30px);
      padding-left: 0;
      margin-left: 15px;
      margin-right: 15px;
    }
    .carouselBox-image {
      border: 0;
    }
    h4 {
      line-height: 28px;
    }
    time {
      font-family: Raleway;
      font-size: 13px;
      font-weight: 600;
    }
    p {
      font-size: 13px;
      margin-top: 15px;
    }

    &-content {
      padding: 10px 20px 30px;
    }

    .link-more {
      margin-right: auto;
    }
  }

  &--journals {
    @media (min-width: $screen-sm-min) { width: calc(33.333333% - 30px); }
  }
}

.themeIndicator {
  span {
    display: none;
    @media (min-width: $screen-sm-min) {
      display: inline-block;
      margin-right: 10px;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 700;
    }
  }
  .themeDecoration {
    margin-right: 5px;
  }

  &--withMargin {
    span { margin-top: 1px; }
  }
}

.ErrorLabel, .EditingFormErrorLabel {
  display: block;
  color: red !important;
  font-size: 13px !important;
  padding-bottom: 10px !important;
}
.EditingFormErrorLabel {
  padding-left: 18px;
}
.Error {
  input, textarea, select {
    border-color: red !important;
    margin-bottom: 0px !important;
  }
}
